import React from 'react';
// import contactDetails from './config'; // Adjust the path as needed
import { useTranslation } from 'react-i18next';
import HomeNav from './HomeNav';
import Footer from './FooterLms';
import i18n from 'i18next';



const contactDetails = {
  whatsappNumber: "+212601938528",
  email: "example@gmail.com",  // Example email
};


const Heade = () => {
  const { t, i18n } = useTranslation(); // i18n gives access to the current language

  return (

    <>
    
    <HomeNav />
    <section className="bg-gray-200 py-10">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-center text-2xl md:text-3xl font-bold mb-8">

          {/* Frequently Asked Questions */}
          {t('home-question-title')}

        </h1>
        
        <div className="space-y-6">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
            
              {/* How can I pay? */}
              {t('home-question-one-question')}


            </h3>
            <p className="text-gray-600 text-base">
              {/* You can pay online on the Ajitfham Academy platform or by transfer through the bank or cash plus and wafacash. */}

                   {t('home-question-one-answer')}

            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
              {/* How can I access the courses and exercises after purchase? */}
              {t('home-question-two-question')}

            </h3>
            <p className="text-gray-600 text-base">
              {/* After the online purchase, you will have direct access to the locked content of the platform.
              But if you pay by transfer, send the receipt by WhatsApp and you will have access the same day. */}
              {t('home-question-two-answer')}

            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
              {/* How do the live sessions work? */}
              {t('home-question-three-question')}

            </h3>
            <p className="text-gray-600 text-base">
              {/* Live sessions are interactive classes where you can ask questions in real time to the teacher. The links for the live sessions are sent by the teachers in the WhatsApp groups. */}
              {t('home-question-three-answer')}

            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
              {/* How will I join the WhatsApp groups and get the teacher's contact details? */}
              {t('home-question-four-question')}

            </h3>
            <p className="text-gray-600 text-base">
              {/* Simply, after registering, send a WhatsApp message to the number {contactDetails.whatsappNumber} which contains your account name (or email). */}
              {/* {t('home-question-four-answer')} */}


              {t('home-question-four-answer').split('+212601938528')[0]}
              <span className="ltr-phone" style={{ direction:"ltr", unicodeBidi:'bidi-override'}} >+212601938528</span>
              {t('home-question-four-answer').split('+212601938528')[1]}


            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
              {/* How can I contact support if I have a problem? */}
              {t('home-question-five-question')}

            </h3>
            <p className="text-gray-600 text-base">
              {/* You can contact our support via WhatsApp at {contactDetails.whatsappNumber}. For more information and assistance, contact us on WhatsApp. */}
              {/* {t('home-question-five-answer')} */}

              {t('home-question-five-answer').split('+212601938528')[0]}
              <span className="ltr-phone" style={{ direction:"ltr", unicodeBidi:'bidi-override'}} >+212601938528</span>
              {t('home-question-five-answer').split('+212601938528')[1]}

            </p>
            <p className="text-center mt-4">
              <a 
                href={`https://wa.me/${contactDetails.whatsappNumber}`} 
                target="_blank" 
                rel="noopener noreferrer"
                className="inline-block py-2 px-4 text-white bg-green-500 rounded-lg text-base font-medium hover:bg-green-600"
              >
                {/* Contact us on WhatsApp */}
                
                {t('home-question-down-whatsapp')}


              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    </>



  );
};

export default Heade;
