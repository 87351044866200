// import { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import toast, { Toaster } from 'react-hot-toast';
// import 'react-quill/dist/quill.snow.css';
// import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
// import WrapperTeacher from "./WrapperTeacher";
// import { useAllCategoriesQuery } from "../../store/services/categoryServiceTeacher";
// import { useCProductMutation } from "../../store/services/productServiceTeacher";
// import Spinner from "../../components/Spinner";
// import ImagesPreview from "../../components/ImagesPreview";
// import { setSuccess } from "../../store/reducers/globalReducer";

// const CreateProduct = () => {
//     const { data = [], isFetching } = useAllCategoriesQuery();
//     const [value, setValue] = useState('');
//     const [state, setState] = useState({
//         title: '',
//         description: '',
//         category: '',
//         price: '',
//         free_videos_count: 2,
//         has_quiz: false,
//         has_test: false,
//         has_assignment: false,
//         thumbnail_url: null,
//     });
 
//     const [preview, setPreview] = useState({
//         image1: '',
//     });

//     const imageHandle = e => {
//         if (e.target.files.length !== 0) {
//             setState({ ...state, [e.target.name]: e.target.files[0] });
//             const reader = new FileReader();
//             reader.onloadend = () => {
//                 setPreview({ ...preview, [e.target.name]: reader.result });
//             }
//             reader.readAsDataURL(e.target.files[0]);
//         }
//     }

//     const handleInput = e => {
//         const { name, type, checked, value } = e.target;
//         if (type === 'checkbox') {
//             setState(prevState => ({ ...prevState, [name]: checked }));
//         } else {
//             setState({ ...state, [name]: value });
//         }
//     }

//     const [createNewProduct, response] = useCProductMutation();
//     console.log('Your response', response);

//     const createPro = e => {
//         e.preventDefault();
//         const formData = new FormData();
//         formData.append('data', JSON.stringify(state));
//         formData.append('description', value);
//         formData.append('thumbnail_url', state.thumbnail_url);
      
//         createNewProduct(formData);
//     }

//     useEffect(() => {
//         if (!response.isSuccess) {
//             response?.error?.data?.errors.forEach(err => {
//                 toast.error(err.msg);
//             });
//         }
//     }, [response?.error?.data?.errors]);

//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (response?.isSuccess) {
//             dispatch(setSuccess(response?.data?.msg));
//             navigate('/dashboard/products');
//         }
//     }, [response?.isSuccess]);

//     return (
//         <WrapperTeacher>
//             <ScreenHeaderTeacher>
//                 <Link to="/dashboard/products" className="btn-dark"><i className="bi bi-arrow-left-short"></i> Products List</Link>
//             </ScreenHeaderTeacher>
//             <Toaster position="top-right" reverseOrder={true} />
//             <div className="flex flex-wrap -mx-3">
//                 <form className="w-full xl:w-8/12 p-3" onSubmit={createPro}>
//                     <div className="flex flex-wrap">
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="title" className="label">Title</label>
//                             <input type="text" name="title" className="form-control" id="title" placeholder="Title..." onChange={handleInput} value={state.title} />
//                         </div>
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="price" className="label">Price ($)</label>
//                             <input type="number" name="price" className="form-control" id="price" placeholder="Price..." onChange={handleInput} value={state.price} />
//                         </div>

//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="description" className="label">Description</label>
//                             <textarea name="description" className="form-control" id="description" placeholder="Description..." onChange={handleInput} value={state.description} />
//                         </div>

//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="has_quiz" className="label">Has Quiz</label>
//                             <input type="checkbox" name="has_quiz" checked={state.has_quiz} className="form-control" id="has_quiz" onChange={handleInput} />
//                         </div>

//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="has_test" className="label">Has Test</label>
//                             <input type="checkbox" name="has_test" checked={state.has_test} className="form-control" id="has_test" onChange={handleInput} />
//                         </div>

//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="has_assignment" className="label">Has Assignment</label>
//                             <input type="checkbox" name="has_assignment" checked={state.has_assignment} className="form-control" id="has_assignment" onChange={handleInput} />
//                         </div>

//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="categories" className="label">Categories</label>
//                             {!isFetching ? data?.categories?.length > 0 && (
//                                 <select name="category" id="categories" className="form-control" onChange={handleInput} value={state.category}>
//                                     <option value="">Choose category</option>
//                                     {data?.categories?.map(category => (
//                                         <option value={category.name} key={category._id}>{category.name}</option>
//                                     ))}
//                                 </select>
//                             ) : <Spinner />}
//                         </div>

//                         <div className="w-full p-3">
//                             <label htmlFor="thumbnail_url" className="label">Thumbnail Image</label>
//                             <input type="file" name="thumbnail_url" id="thumbnail_url" className="input-file" onChange={imageHandle} />
//                         </div>

//                         <div className="w-full p-3">
//                             <input type="submit" value={response.isLoading ? 'Loading...' : 'Save Product'} disabled={response.isLoading} className="btn btn-indigo" />
//                         </div>
//                     </div>
//                 </form>
//                 <div className="w-full xl:w-4/12 p-3">
//                 <div className="w-full xl:w-4/12 p-3">
//     <ImagesPreview url={preview.image1} heading="Image 1" />
// </div>
//                     <ImagesPreview url={preview.image1} heading="Image 1" />
//                 </div>
//             </div>
//         </WrapperTeacher>
//     );
// }

// export default CreateProduct;




import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import 'react-quill/dist/quill.snow.css';
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import WrapperTeacher from "./WrapperTeacher";
// import { useAllCategoriesQuery } from "../../store/services/categoryServiceTeacher";
import { useCProductMutation ,useAllCategoriesQuery} from "../../store/services/productServiceTeacher";
import Spinner from "../../components/Spinner";
import ImagesPreview from "../../components/ImagesPreview";
import { setSuccess } from "../../store/reducers/globalReducer";
import ReactQuill from "react-quill";






const CreateProduct = () => {
    const { t } = useTranslation(); // Initialize translation hook

    const {data = [], isFetching} = useAllCategoriesQuery();
    const [value, setValue] = useState('');
    const [state, setState] = useState({
        title: '',
        price: 0,
        category: '',
        image1: '',
        instructorId:''

    });
    const [preview, setPreview] = useState({
        image1: ''
    });

    const imageHandle = e => {
        if(e.target.files.length !== 0) {
            setState({...state, [e.target.name]: e.target.files[0]});
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({...preview, [e.target.name]: reader.result})
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleInput = e => {
        setState({...state, [e.target.name]: e.target.value});
    };

    const [createNewProduct, response] = useCProductMutation();
    console.log('Your response', response);

    const createPro = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('data', JSON.stringify(state));
        formData.append('description', value);
        formData.append('image1', state.image1);
    
        // Retrieve and append instructor-id from local storage
        const instructorId = localStorage.getItem('instructor-id');
        if (instructorId) {
            formData.append('instructorId', instructorId);
        } else {
            toast.error(t('dashboard-teacher-createcourse-errorInstructorIdMissing'));

            // toast.error("Instructor ID is missing");
            return;
        }
    
        createNewProduct(formData);
    };
    
    // useEffect(() => {
    //     if (response?.error?.data?.errors && Array.isArray(response.error.data.errors)) {
    //         response.error.data.errors.forEach(err => {
    //             toast.error(err.msg);
    //         });
    //     } else if (response?.error) {
    //         toast.error("An unexpected error occurred.");
    //     }
    // }, [response?.error?.data?.errors]);

    useEffect(() => {
        if (response?.error?.data?.errors && Array.isArray(response.error.data.errors)) {
            response.error.data.errors.forEach(err => {
                toast.error(t('dashboard-teacher-createcourse-errorUnexpected'));
            });
        } else if (response?.error) {
            toast.error(t('dashboard-teacher-createcourse-errorUnexpected'));
        }
    }, [response?.error?.data?.errors, t]);


     const dispatch = useDispatch();
    const navigate = useNavigate();
    
    // useEffect(() => {
    //     if (response?.isSuccess) {
    //         dispatch(setSuccess(response.data.msg));
    //         console.log("this dcall  asdasd")
    //         navigate('/dashboard/teacher');
    //     }
    // }, [response?.isSuccess]);

    useEffect(() => {
        if (response?.isSuccess) {
            dispatch(setSuccess(t('dashboard-teacher-createcourse-success')));
            navigate('/dashboard/teacher');
        }
    }, [response?.isSuccess, dispatch, navigate, t]);
    

    // useEffect(() => {
    //     if(!response.isSuccess) {
    //         response?.error?.data?.errors.map(err => {
    //             toast.error(err.msg);
    //         });
    //     }
    // }, [response?.error?.data?.errors]);

    useEffect(() => {
        if (!response.isSuccess) {
            response?.error?.data?.errors.map(err => {
                toast.error(t('dashboard-teacher-createcourse-errorUnexpected'));
            });
        }
    }, [response?.error?.data?.errors, t]);

    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    // useEffect(() => {
    //     if(response?.isSuccess) {
    //         dispatch(setSuccess(response?.data?.msg));
    //         navigate('/dashboard/products');
    //     }
    // }, [response?.isSuccess]);

    return (
        <WrapperTeacher>
            <ScreenHeaderTeacher>
                <Link to="/dashboard/teacher" className="btn-dark">
                    <i className="bi bi-arrow-left-short"></i>
                     {/* Course List */}
                     {t('dashboard-teacher-createcourse-back')}
                </Link>
            </ScreenHeaderTeacher>
            <Toaster position="top-right" reverseOrder={true} />
            <div className="flex flex-wrap -mx-3">
                <form className="w-full xl:w-8/12 p-3" onSubmit={createPro}>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="title" className="label">
                                {/* Title */}
                                {t('dashboard-teacher-createcourse-title')}
                                </label>
                            <input type="text" name="title" className="form-control" id="title" placeholder="Title..." onChange={handleInput} value={state.title} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="price" className="label">
                                {/* Price */}
                                {t('dashboard-teacher-createcourse-price')}
                                </label>
                            <input type="number" name="price" className="form-control" id="price" placeholder="Price..." onChange={handleInput} value={state.price} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="categories" className="label">
                                {/* Categories */}
                                {t('dashboard-teacher-createcourse-category')}
                                </label>
                            {!isFetching ? data?.categories?.length > 0 && (
                                <select name="category" id="categories" className="form-control" onChange={handleInput} value={state.category}>
                                    <option value="">
                                        {/* Choose category */}
                                        {t('dashboard-teacher-createcourse-category')}
                                        </option>
                                    {data?.categories?.map(category => (
                                        <option value={category.name} key={category._id}>{category.name}</option>
                                    ))}
                                </select>
                            ) : <Spinner />}
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="image1" className="label">
                                {/* Image 1 */}
                                {t('dashboard-teacher-createcourse-image')}
                                </label>
                            <input type="file" name="image1" id="image1" className="input-file" onChange={imageHandle} />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="description" className="label">
                                {/* Description */}
                                {t('dashboard-teacher-createcourse-description')}
                                </label>
                            <ReactQuill theme="snow" id="description" value={value} onChange={setValue} placeholder="Description..." />
                        </div>
                        <div className="w-full p-3">
                            {/* <input type="submit" value={response.isLoading ? 'Loading...' : 'Save Product'} disabled={response.isLoading} className="btn btn-indigo" /> */}
                            <input type="submit" value={response.isLoading ? t('dashboard-teacher-createcourse-loading') : t('dashboard-teacher-createcourse-saveButton')} disabled={response.isLoading} className="btn btn-indigo" />

                        
                        </div>
                    </div>
                </form>
                <div className="w-full xl:w-4/12 p-3">
                    {/* <ImagesPreview url={preview.image1} heading="Image 1" /> */}
                    <ImagesPreview url={preview.image1} heading={t('dashboard-teacher-createcourse-image')} />

                </div>
            </div>
        </WrapperTeacher>
    );
};

export default CreateProduct;














// const CreateProduct = () => {
//     const { data = [], isFetching } = useAllCategoriesQuery();
//     const [value, setValue] = useState('');
//     const [state, setState] = useState({
//         title: '',
//         description: '',
//         category: '',
//         price: '',
//         free_videos_count: 2,
//         has_quiz: false,
//         has_test: false,
//         has_assignment: false,
//         thumbnail_url: "",
//     });

//     const [preview, setPreview] = useState({
//         thumbnail_url: '',
//     });

//     const imageHandle = e => {
//         if (e.target.files.length !== 0) {
//             setState(prevState => ({
//                 ...prevState,
//                 thumbnail_url: e.target.files[0]
//             }));
//             const reader = new FileReader();
//             reader.onloadend = () => {
//                 setPreview({ ...preview, thumbnail_url: reader.result });
//             }
//             reader.readAsDataURL(e.target.files[0]);
//         }
//     }

//     const handleInput = e => {
//         const { name, type, checked, value } = e.target;
//         if (type === 'checkbox') {
//             setState(prevState => ({ ...prevState, [name]: checked }));
//         } else {
//             setState(prevState => ({ ...prevState, [name]: value }));
//         }
//     }

//     const [createNewProduct, response] = useCProductMutation();
//     console.log('Your response', response);

//     // const createPro = e => {
//     //     e.preventDefault();
//     //     const formData = new FormData();
        
//     //     // Append other fields
//     //     formData.append('data', JSON.stringify(state));
//     //     formData.append('description', value);

//     //     // Append thumbnail_url only if it is a valid file
//     //     if (state.thumbnail_url) {
//     //         console.log("'thumbnail_url', state.thumbnail_url" ,state.thumbnail_url)
//     //         formData.append('thumbnail_url', state.thumbnail_url);
//     //     } else {
//     //         toast.error('Thumbnail image is required');
//     //         return;
//     //     }

//     //     // Log FormData entries for debugging
//     //     for (let [key, value] of formData.entries()) {
//     //         console.log(`${key}:`, value);
//     //     }

//     //     createNewProduct(formData);
//     // }
//     const createPro = e => {
//         e.preventDefault();
//         const formData = new FormData();
        
//         // Append fields
//         formData.append('data', JSON.stringify(state));
//         formData.append('description', value);
//         formData.append('thumbnail_url', state.thumbnail_url);
    
//         // Append thumbnail_url if a file is selected
//         // if (state.thumbnail_url) {
//         //     formData.append('thumbnail_url', state.thumbnail_url);
//         // } else {
//         //     toast.error('Thumbnail image is required');
//         //     return;
//         // }
    
//         // Log FormData entries for debugging
//         for (let [key, value] of formData.entries()) {
//             console.log(`${key}:`, value);
//         }
    
//         createNewProduct(formData);
//     }
    

//     useEffect(() => {
//         if (!response.isSuccess) {
//             response?.error?.data?.errors.forEach(err => {
//                 toast.error(err.msg);
//             });
//         }
//     }, [response?.error?.data?.errors]);

//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (response?.isSuccess) {
//             dispatch(setSuccess(response?.data?.msg));
//             navigate('/dashboard/products');
//         }
//     }, [response?.isSuccess]);

//     return (
//         <WrapperTeacher>
//             <ScreenHeaderTeacher>
//                 <Link to="/dashboard/products" className="btn-dark"><i className="bi bi-arrow-left-short"></i> Products List</Link>
//             </ScreenHeaderTeacher>
//             <Toaster position="top-right" reverseOrder={true} />
//             <div className="flex flex-wrap -mx-3">
//                 <form className="w-full xl:w-8/12 p-3" onSubmit={createPro}>
//                     <div className="flex flex-wrap">
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="title" className="label">Title</label>
//                             <input type="text" name="title" className="form-control" id="title" placeholder="Title..." onChange={handleInput} value={state.title} />
//                         </div>
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="price" className="label">Price ($)</label>
//                             <input type="number" name="price" className="form-control" id="price" placeholder="Price..." onChange={handleInput} value={state.price} />
//                         </div>

//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="description" className="label">Description</label>
//                             <textarea name="description" className="form-control" id="description" placeholder="Description..." onChange={handleInput} value={state.description} />
//                         </div>

//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="has_quiz" className="label">Has Quiz</label>
//                             <input type="checkbox" name="has_quiz" checked={state.has_quiz} className="form-control" id="has_quiz" onChange={handleInput} />
//                         </div>

//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="has_test" className="label">Has Test</label>
//                             <input type="checkbox" name="has_test" checked={state.has_test} className="form-control" id="has_test" onChange={handleInput} />
//                         </div>

//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="has_assignment" className="label">Has Assignment</label>
//                             <input type="checkbox" name="has_assignment" checked={state.has_assignment} className="form-control" id="has_assignment" onChange={handleInput} />
//                         </div>

//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="categories" className="label">Categories</label>
//                             {!isFetching ? data?.categories?.length > 0 && (
//                                 <select name="category" id="categories" className="form-control" onChange={handleInput} value={state.category}>
//                                     <option value="">Choose category</option>
//                                     {data?.categories?.map(category => (
//                                         <option value={category.name} key={category._id}>{category.name}</option>
//                                     ))}
//                                 </select>
//                             ) : <Spinner />}
//                         </div>

//                         <div className="w-full p-3">
//                             <label htmlFor="thumbnail_url" className="label">Thumbnail Image</label>
//                             <input type="file" name="thumbnail_url" id="thumbnail_url" className="input-file" onChange={imageHandle} />
//                         </div>

//                         <div className="w-full p-3">
//                             <input type="submit" value={response.isLoading ? 'Loading...' : 'Save Product'} disabled={response.isLoading} className="btn btn-indigo" />
//                         </div>
//                     </div>
//                 </form>
//                 <div className="w-full xl:w-4/12 p-3">
//                     <ImagesPreview url={preview.thumbnail_url} heading="Image 1" />
//                 </div>
//             </div>
//         </WrapperTeacher>
//     );
// }

// export default CreateProduct;































































































































// import {useState, useEffect} from "react";
// import { Link, useNavigate } from "react-router-dom"
// import {useDispatch} from "react-redux"
// import {TwitterPicker} from "react-color"
// import { v4 as uuidv4 } from 'uuid';
// import ReactQuill from "react-quill"
// import toast, { Toaster } from 'react-hot-toast';
// import 'react-quill/dist/quill.snow.css';
// import ScreenHeader from "../../components/ScreenHeader"
// import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher"
// import Wrapper from "./Wrapper"
// import WrapperTeacher from "./WrapperTeacher"
// import { useAllCategoriesQuery } from "../../store/services/categoryServiceTeacher"
// import { useCProductMutation } from "../../store/services/productServiceTeacher";
// import Spinner from "../../components/Spinner"
// import Colors from "../../components/Colors";
// import SizesList from "../../components/SizesList";
// import ImagesPreview from "../../components/ImagesPreview";
// import { setSuccess } from "../../store/reducers/globalReducer";



// const CreateProduct = () => {
//     const { data = [], isFetching } = useAllCategoriesQuery();
//     const [value, setValue] = useState('');
//     const [state, setState] = useState({
//         title: '',
//         description: '',
//         category: '',
//         price: '',
//         free_videos_count: 2,
//         has_quiz: false,
//         has_test: false,
//         has_assignment: false,
//         thumbnail_url: null,
//     });
 
//     const [preview, setPreview] = useState({
//         image1: '',
//     })
//     const imageHandle = e => {
//         if (e.target.files.length !== 0) {
//             setState({ ...state, [e.target.name]: e.target.files[0] });
//             const reader = new FileReader();
//             reader.onloadend = () => {
//                 setPreview({ ...preview, [e.target.name]: reader.result })
//             }
//             reader.readAsDataURL(e.target.files[0]);
//         }
//     }
//     const handleInput = e => {
//         setState({ ...state, [e.target.name]: e.target.value })
//     }

//     const [createNewProduct, response] = useCProductMutation();
//     console.log('Your response', response)
//     const createPro = e => {
//         e.preventDefault();
//         const formData = new FormData();
//         formData.append('data', JSON.stringify(state));
//         formData.append('description', value)
//         formData.append('thumbnail_url', state.thumbnail_url)
      
//         createNewProduct(formData);
//     }
//     useEffect(() => {
//         if (!response.isSuccess) {
//             response?.error?.data?.errors.map(err => {
//                 toast.error(err.msg);
//             })
//         }
//     }, [response?.error?.data?.errors])
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     useEffect(() => {
//         if (response?.isSuccess) {
//             dispatch(setSuccess(response?.data?.msg));
//             navigate('/dashboard/products');
//         }
//     }, [response?.isSuccess])
//     return (
//         <WrapperTeacher>
//             <ScreenHeaderTeacher>
//                 <Link to="/dashboard/products" className="btn-dark"><i className="bi bi-arrow-left-short"></i> proudcts list</Link>
//             </ScreenHeaderTeacher>
//             <Toaster position="top-right" reverseOrder={true} />
//             <div className="flex flex-wrap -mx-3">
//                 <form className="w-full xl:w-8/12 p-3" onSubmit={createPro}>
//                     <div className="flex flex-wrap">
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="title" className="label">title</label>
//                             <input type="text" name="title" className="form-control" id="title" placeholder="title..." onChange={handleInput} value={state.title} />
//                         </div>
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="price" className="label">price ($)</label>
//                             <input type="number" name="price" className="form-control" id="price" placeholder="price..." onChange={handleInput} value={state.price} />
//                         </div>

//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="title" className="label">Description</label>
//                             <textarea  name="description" className="form-control" id="description" placeholder="Description..." onChange={handleInput} value={state.description} />
//                         </div>

                     



//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="has_quiz" className="label">Has Quiz</label>
//                             <input  type="checkbox" name="has_quiz" checked={state.has_quiz}  className="form-control" id="has_quiz"  onChange={handleInput} value={state.has_quiz} />
//                         </div>

//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="discount" className="label">Has Test</label>
//                             <input  type="checkbox" name="has_test" checked={state.has_quiz}  className="form-control" id="has_test"  onChange={handleInput} value={state.has_test} />
//                         </div>

                        
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="discount" className="label">Has Assignment</label>
//                             <input  type="checkbox" name="has_assignment" checked={state.has_quiz}  className="form-control" id="has_assignment"  onChange={handleInput} value={state.has_assignment} />
//                         </div>


                     
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="categories" className="label">categories</label>
//                             {!isFetching ? data?.categories?.length > 0 && <select name="category" id="categories" className="form-control" onChange={handleInput} value={state.category}>
//                                 <option value="">Choose category</option>
//                                 {data?.categories?.map(category => (
//                                     <option value={category.name} key={category._id}>{category.name}</option>
//                                 ))}
//                             </select> : <Spinner />}
//                         </div>
                       

//                         <div className="w-full p-3">
//                             <label htmlFor="thumbnail_url" className="label">
//                             Thumbnail Image                            </label>
//                             <input type="file" name="thumbnail_url" id="thumbnail_url" className="input-file" onChange={imageHandle} />
//                         </div>

                
//                         <div className="w-full p-3">
//                             <input type="submit" value={response.isLoading ? 'loading...' : 'save product'} disabled={response.isLoading ? true : false} className="btn btn-indigo" />
//                         </div>
//                     </div>
//                 </form>
//                 <div className="w-full xl:w-4/12 p-3">
//                     <ImagesPreview url={preview.image1} heading="image 1" />
//                 </div>
//             </div>
//         </WrapperTeacher>
//     )
// }
// export default CreateProduct

