// import { useEffect } from "react";
// import { Link, useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import toast, { Toaster } from 'react-hot-toast';
// import { clearMessage } from "../../store/reducers/globalReducer";
// import WrapperTeacher from "./WrapperTeacher";
// import { useGetProductsQuery, useDeleteProductMutation } from "../../store/services/productServiceTeacher";
// import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
// import Spinner from "../../components/Spinner";
// import Pagination from "../../components/Pagination";

// const TeacherPanel = () => {
//     let { page } = useParams();
//     if (!page) {
//         page = 1;
//     }

//     const teacherId = localStorage.getItem('instructor-id'); // Assuming you store the teacher ID in local storage

//     const { data = [], isFetching } = useGetProductsQuery({ page, teacherId });
//     const { success } = useSelector(state => state.globalReducer);
//     const dispatch = useDispatch();

//     useEffect(() => {
//         if (success) {
//             toast.success(success);
//         }
//         return () => {
//             dispatch(clearMessage());
//         };
//     }, [success, dispatch]);

//     const [delProduct] = useDeleteProductMutation();

//     const deleteProduct = id => {
//         if (window.confirm("Are you sure you want to delete this product?")) {
//             delProduct(id);
//         }
//     };

//     return (
//         <WrapperTeacher>
//             <ScreenHeaderTeacher>
//                 <Link to="/dashboard/create-product" className="btn-dark">Create Product</Link>
//                 <Toaster position="top-right" />
//             </ScreenHeaderTeacher>
//             {!isFetching ? (data?.products?.length > 0 ? (
//                 <div>
//                     <table className="w-full bg-gray-900 rounded-md">
//                         <thead>
//                             <tr className="border-b border-gray-800 text-left">
                              //   <th className="p-3 uppercase text-sm font-medium text-gray-500">Title</th>
                              //   <th className="p-3 uppercase text-sm font-medium text-gray-500">Price</th>
                              //   <th className="p-3 uppercase text-sm font-medium text-gray-500">Category</th>
                              //   <th className="p-3 uppercase text-sm font-medium text-gray-500">Image</th>
                              //   <th className="p-3 uppercase text-sm font-medium text-gray-500">Edit</th>
                              //   <th className="p-3 uppercase text-sm font-medium text-gray-500">Delete</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {data?.products?.map(product => (
//                                 <tr className="odd:bg-gray-800" key={product._id}>
//                                     <td className="p-3 capitalize text-sm font-normal text-gray-400">{product.title}</td>
//                                     <td className="p-3 capitalize text-sm font-normal text-gray-400">${product.price}</td>
//                                     <td className="p-3 capitalize text-sm font-normal text-gray-400">{product.category}</td>
//                                     <td className="p-3 capitalize text-sm font-normal text-gray-400">
//                                         <img src={`/images/${product.image1}`} alt="Product" className="w-20 h-20 rounded-md object-cover" />
//                                     </td>
//                                     <td className="p-3 capitalize text-sm font-normal text-gray-400">
//                                         <Link to={`/dashboard/edit-product/${product._id}`} className="btn btn-warning">Edit</Link>
//                                     </td>
//                                     <td className="p-3 capitalize text-sm font-normal text-gray-400">
//                                         <span className="btn btn-danger cursor-pointer" onClick={() => deleteProduct(product._id)}>Delete</span>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                     <Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/products" />
//                 </div>
//             ) : 'No products!' : <Spinner />}
//         </WrapperTeacher>
//     );
// };

// export default TeacherPanel;







import {useEffect} from "react"
import { useTranslation } from 'react-i18next';

import { Link, useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import toast, { Toaster } from 'react-hot-toast';
import { clearMessage } from "../../store/reducers/globalReducer";
import Wrapper from "./Wrapper"
import WrapperTeacher from "./WrapperTeacher"
// import { useGetProductsQuery, useDeleteProductMutation } from "../../store/services/productService";
import { useGetProductsQuery, useDeleteProductMutation } from "../../store/services/productServiceTeacher";
import ScreenHeader from "../../components/ScreenHeader";
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
const apiimage = process.env.REACT_APP_API_PICTURE;

const TeacherPanel = () => {
   const { t } = useTranslation();

   let {page} = useParams();
   if(!page) {
      page = 1;
   }

   const teacherId = localStorage.getItem('instructor-id'); // Assuming you store the teacher ID in local storage

   const {data = [], isFetching} = useGetProductsQuery({ page, teacherId });
   // const serverBaseURL = 'https://api.wgktrade.com'; // Change this as needed
   const serverBaseURL = apiimage; // Change this as needed


   console.log(data);
   const {success} = useSelector(state => state.globalReducer);
    const dispatch = useDispatch();
    useEffect(() => {
     if(success) {
       toast.success(success);
     }
     return () => {
        dispatch(clearMessage())
     }
    }, [])
    const [delProduct, response] = useDeleteProductMutation();
    
    const deleteProduct = id => {
      if(window.confirm("Are you really want to delete this product?")) {
          delProduct(id);
      }
    }
    return(
       <WrapperTeacher >
         <ScreenHeaderTeacher>
          {/* <Link to="/dashboard/create-product" className="btn-dark">create Course</Link> */}
          <Link to="/dashboard/teacher/create-course" className="btn-dark">
          {/* create Course */}
          {t('dashboard-teacher-create-course')}
          </Link>
          <Toaster position="top-right" />
          </ScreenHeaderTeacher>
          {!isFetching ? data?.products?.length > 0 ? <div>
            <table className="w-full bg-gray-900 rounded-md">
            <thead>
                    <tr className="border-b border-gray-800 text-left">
                    <th className="p-3 uppercase text-sm font-medium text-gray-500">
                     {/* Title */}
                     {t('dashboard-teacher-title')}
                     </th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">
                                 {/* Price */}
                                 {t('dashboard-teacher-price')}
                                 </th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">
                                 {/* Category */}
                                 {t('dashboard-teacher-category')}
                                 </th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">
                                 {/* Image */}
                                 {t('dashboard-teacher-image')}
                                 </th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">
                                 {/* Edit */}
                                 {t('dashboard-teacher-edit')}
                                 </th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">
                                 {/* Delete */}
                                 {t('dashboard-teacher-delete')}
                                 </th>
                    </tr>
                 </thead>
                 <tbody>
                  {data?.products?.map(product => (
                     <tr className="odd:bg-gray-800" key={product._id}>
                        <td className="p-3 capitalize text-sm font-normal text-gray-400">{product.title}</td>
                        <td className="p-3 capitalize text-sm font-normal text-gray-400">${product.price}.00</td>
                        <td className="p-3 capitalize text-sm font-normal text-gray-400">{product.category}</td>
                      
                           <td className="p-3 capitalize text-sm font-normal text-gray-400">
                                        <img src={`${serverBaseURL}${product.thumbnail_url}`} alt="Product" className="w-20 h-20 rounded-md object-cover" />
                                     </td>
                                     <td className="p-3 capitalize text-sm font-normal text-gray-400"><Link to={`/dashboard/edit-product/${product._id}`} className="btn btn-warning">
                                     {/* edit */}
                                     {t('dashboard-teacher-edit-product')}
                                     
                                     </Link></td>
                                     <td className="p-3 capitalize text-sm font-normal text-gray-400"><Link to={`/dashboard/edit-video/${product._id}`} className="btn btn-warning">VIDEO</Link></td>
                                     <td className="p-3 capitalize text-sm font-normal text-gray-400"><span className="btn btn-danger cursor-pointer" onClick={() => deleteProduct(product._id)}>
                                       {/* delete */}
                                       {t('dashboard-teacher-delete-product')}
                                       </span></td>
                     </tr>
                  ))}
                 </tbody>
            </table>
            <Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/teacher" />
          </div> : 'No products!' : <Spinner />}
       </WrapperTeacher>
    )
}
export default TeacherPanel;











