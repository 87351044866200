import React from 'react';
// Import the image as a background
import bgImage from "../../assets/images/mm2.png"
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Heada = () => {
  const { t } = useTranslation();

  return (
    <div 
      className="flex items-center justify-center sm:h-[400px] p-8 lg:p-16 mt-[60px] bg-cover bg-center" 
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="text-center w-full sm:h-[300px] max-w-lg px-4  text-black rounded-lg">
      {/* <div className="text-center w-full sm:h-[300px] max-w-lg px-4 bg-opacity-50 bg-black text-white rounded-lg"> */}
      <h3 className="text-2xl sm:text-3xl  font-[px] font-bold mb-4 text-black">
      {/* Your Moroccan Online Educational Platform for Excellence in Learning */}
      {t('home-s-a-l-a')}

      </h3>
      {/* <h3>
      {t('welcome')}
      </h3> */}
        <p className="text-base font-bold  font-[16px] sm:text-lg mb-6 text-black ">
        {/* Ajitfham Academy is a leading online educational platform in Morocco, dedicated to providing high-quality learning resources to Moroccan students. */}
        {t('home-s-a-l-b')}

      
</p>
{/* 
<h3
  className="text-2xl sm:text-3xl font-[px] font-bold mb-4 text-white"
  style={{ textShadow: '2px 2px 3px black' }}
>
  Your Moroccan Online Educational Platform for Excellence in Learning
</h3>
<p
  className="text-base font-bold font-[16px] sm:text-lg mb-6 text-white"
  style={{ textShadow: '3px 3px 2px black' }}
>
  Ajitfham Academy is a leading online educational platform in Morocco, dedicated to providing high-quality learning resources to Moroccan students.
</p> */}


<Link className="bg-blue-600 text-white py-2 px-4 rounded-lg text-lg font-medium hover:bg-red-500 " to="/coursesmain">
 {/* See All Courses */}
 {t('home-s-a-l-c')}

 </Link>
{/* 
        <a
          href="/coursesmain"
          className="bg-blue-600 text-white py-2 px-4 rounded-lg text-lg font-medium hover:bg-blue-700"
        >
See All Courses        </a> */}
      </div>
    </div>
  );
};

export default Heada;
