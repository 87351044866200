// import { useState, useEffect } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import ReactQuill from "react-quill";
// import toast, { Toaster } from "react-hot-toast";
// import h2p from "html2plaintext";
// import "react-quill/dist/quill.snow.css";
// import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
// import WrapperTeacher from "./WrapperTeacher";
// // import { useAllCategoriesQuery } from "../../store/services/categoryServiceTeacher";
// import { useUpdateCourseAdminMutation, useGetSingleCourseAdminQuery,useGetadminAllCategoriesQuery } from "../../store/services/productService";
// import Spinner from "../../components/Spinner";
// import { setSuccess } from "../../store/reducers/globalReducer";
// import Wrapper from "./Wrapper";
// import ScreenHeader from "../../components/ScreenHeader";

// const EditProduct = () => {
//   const { id } = useParams();
//   const { data: product, isFetching: fetching } = useGetSingleCourseAdminQuery(id);
//   const { data = [], isFetching: categoriesFetching } = useGetadminAllCategoriesQuery();
//   const [value, setValue] = useState('');
//   const [state, setState] = useState({
//     title: "",
//     price: 0,
//     category: "",
//     description: '',
//     productId: id,
//     image1: null
//   });
//   const [imagePreview, setImagePreview] = useState("");

//   const handleInput = (e) => {
//     setState({ ...state, [e.target.name]: e.target.value });
//   };

//   const handleFileChange = (e) => {
//     setState({ ...state, image1: e.target.files[0] });
//     const reader = new FileReader();
//     reader.onload = () => {
//       setImagePreview(reader.result);
//     };
//     if (e.target.files[0]) {
//       reader.readAsDataURL(e.target.files[0]);
//     }
//   };

//   const [updateProduct, response] = useUpdateCourseAdminMutation();

//   const updateProductHandler = (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append('data', JSON.stringify({
//       title: state.title,
//       price: state.price,
//       category: state.category,
//       description: value,
//       productId: state.productId
//     }));
//     formData.append('description', state.description); // Text area content
//     if (state.image1) {
//       formData.append('image1', state.image1);
//     }

//     updateProduct(formData);
//   };

//   useEffect(() => {
//     if (!response.isSuccess && response?.error?.data?.errors) {
//       response.error.data.errors.forEach((err) => {
//         toast.error(err.msg);
//       });
//     }
//   }, [response?.error?.data?.errors]);

//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (response?.isSuccess) {
//       dispatch(setSuccess(response?.data?.msg));
//       navigate("/dashboard/get/admin/course");
//     }
//   }, [response?.isSuccess]);

//   useEffect(() => {
//     if (!fetching && product) {
//       setState({
//         title: product.title,
//         price: product.price,
//         category: product.category,
//         description: h2p(product.description),
//         productId: id
//       });
//       setValue(h2p(product.description));
//       if (product.thumbnail_url) {
//         setImagePreview(product.thumbnail_url);
//       }
//     }
//   }, [product, fetching]);

//   return (
//     <Wrapper>
//       <ScreenHeader>
//         <Link to="/dashboard/get/admin/course" className="btn-dark">
//           <i className="bi bi-arrow-left-short"></i> Back
//         </Link>
//       </ScreenHeader>
//       <Toaster position="top-right" reverseOrder={true} />
//       {!fetching ? (
//         <div className="flex flex-wrap -mx-3 bg-yellow-300">
//           <form className="w-full xl:w-8/12 p-3" onSubmit={updateProductHandler}>
//             <h3 className="pl-3 capitalize text-lg font-medium text-gray-400">
//               Edit Course Details
//             </h3>
//             <div className="flex flex-wrap">
//               <div className="w-full md:w-6/12 p-3">
//                 <label htmlFor="title" className="label">
//                   Title
//                 </label>
//                 <input
//                   type="text"
//                   name="title"
//                   className="form-control"
//                   id="title"
//                   placeholder="Title..."
//                   onChange={handleInput}
//                   value={state.title}
//                 />
//               </div>
//               <div className="w-full md:w-6/12 p-3">
//                 <label htmlFor="price" className="label">
//                   Price
//                 </label>
//                 <input
//                   type="number"
//                   name="price"
//                   className="form-control"
//                   id="price"
//                   placeholder="Price..."
//                   onChange={handleInput}
//                   value={state.price}
//                 />
//               </div>
//               <div className="w-full md:w-6/12 p-3">
//                 <label htmlFor="category" className="label">
//                   Category
//                 </label>
//                 {!categoriesFetching ? (
//                   data?.categories?.length > 0 && (
//                     <select
//                       name="category"
//                       id="category"
//                       className="form-control"
//                       onChange={handleInput}
//                       value={state.category}
//                     >
//                       <option value="">Choose category</option>
//                       {data?.categories?.map((category) => (
//                         <option value={category.name} key={category._id}>
//                           {category.name}
//                         </option>
//                       ))}
//                     </select>
//                   )
//                 ) : (
//                   <Spinner />
//                 )}
//               </div>

//               <div className="w-full p-3">
//                 <label htmlFor="description" className="label">
//                   Description
//                 </label>
//                 <ReactQuill
//                   theme="snow"
//                   id="description"
//                   value={value}
//                   onChange={setValue}
//                   placeholder="Description..."
//                 />
//               </div>

//               <div className="w-full p-3">
//                 <label htmlFor="image1" className="label">
//                   Product Image
//                 </label>
//                 <input
//                   type="file"
//                   name="image1"
//                   id="image1"
//                   accept="image/jpeg, image/png"
//                   onChange={handleFileChange}
//                   className="form-control"
//                 />
//                 {imagePreview && (
//                   <div className="mt-2">
//                     <img
//                       src={imagePreview}
//                       alt="Product Preview"
//                       className="w-full h-auto"
//                     />
//                   </div>
//                 )}
//               </div>

//               <div className="w-full p-3">
//                 <input
//                   type="submit"
//                   value={response.isLoading ? "Loading..." : "Save Product"}
//                   disabled={response.isLoading}
//                   className="btn btn-indigo"
//                 />
//               </div>
//             </div>
//           </form>
//         </div>
//       ) : (
//         <Spinner />
//       )}
//     </Wrapper>
//   );
// };

// export default EditProduct;






import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import toast, { Toaster } from "react-hot-toast";
import h2p from "html2plaintext";
import "react-quill/dist/quill.snow.css";
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper";
import { useUpdateCourseAdminMutation, useGetSingleCourseAdminQuery, useGetadminAllCategoriesQuery } from "../../store/services/productService";
import Spinner from "../../components/Spinner";
import { setSuccess } from "../../store/reducers/globalReducer";
import { useTranslation } from 'react-i18next';
const apiimage = process.env.REACT_APP_API_PICTURE;

const EditProduct = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const serverBaseURL = apiimage
  const { data: product, isFetching: fetching } = useGetSingleCourseAdminQuery(id);
  const { data = [], isFetching: categoriesFetching } = useGetadminAllCategoriesQuery();
  const [value, setValue] = useState('');
  const [state, setState] = useState({
    title: "",
    price: 0,
    category: "",
    description: '',
    productId: id,
    image1: null
  });
  const [imagePreview, setImagePreview] = useState("");

  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setState({ ...state, image1: e.target.files[0] });
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const [updateProduct, response] = useUpdateCourseAdminMutation();

  const updateProductHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('data', JSON.stringify({
      title: state.title,
      price: state.price,
      category: state.category,
      description: value,
      productId: state.productId
    }));
    formData.append('description', state.description); // Text area content
    if (state.image1) {
      formData.append('image1', state.image1);
    }

    updateProduct(formData);
  };

  useEffect(() => {
    if (!response.isSuccess && response?.error?.data?.errors) {
      response.error.data.errors.forEach((err) => {
        toast.error(err.msg);
      });
    }
  }, [response?.error?.data?.errors]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (response?.isSuccess) {
      dispatch(setSuccess(t('admin-dashboard-course-edit-success')));
      navigate("/dashboard/get/admin/course");
    }
  }, [response?.isSuccess]);

  useEffect(() => {
    if (!fetching && product) {
      setState({
        title: product.title,
        price: product.price,
        category: product.category,
        description: h2p(product.description),
        productId: id
      });
      setValue(h2p(product.description));
      if (product.thumbnail_url) {
        setImagePreview(product.thumbnail_url);
      }
    }
  }, [product, fetching]);

  return (
    <Wrapper>
      <ScreenHeader>
        <Link to="/dashboard/get/admin/course" className="btn-dark">
          <i className="bi bi-arrow-left-short"></i> {t('admin-dashboard-course-edit-back')}
        </Link>
      </ScreenHeader>
      <Toaster position="top-right" reverseOrder={true} />
      {!fetching ? (
        <div className="flex flex-wrap -mx-3 bg-yellow-300">
          <form className="w-full xl:w-8/12 p-3" onSubmit={updateProductHandler}>
            <h3 className="pl-3 capitalize text-lg font-medium text-gray-400">
              {t('admin-dashboard-course-edit-title')}
            </h3>
            <div className="flex flex-wrap">
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="title" className="label">
                  {t('admin-dashboard-course-edit-title')}
                </label>
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  id="title"
                  placeholder={t('admin-dashboard-course-edit-title-placeholder')}
                  onChange={handleInput}
                  value={state.title}
                />
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="price" className="label">
                  {t('admin-dashboard-course-edit-price')}
                </label>
                <input
                  type="number"
                  name="price"
                  className="form-control"
                  id="price"
                  placeholder={t('admin-dashboard-course-edit-price-placeholder')}
                  onChange={handleInput}
                  value={state.price}
                />
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="category" className="label">
                  {t('admin-dashboard-course-edit-category')}
                </label>
                {!categoriesFetching ? (
                  data?.categories?.length > 0 && (
                    <select
                      name="category"
                      id="category"
                      className="form-control"
                      onChange={handleInput}
                      value={state.category}
                    >
                      <option value="">{t('admin-dashboard-course-edit-category')}</option>
                      {data?.categories?.map((category) => (
                        <option value={category.name} key={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  )
                ) : (
                  <Spinner />
                )}
              </div>

              <div className="w-full p-3">
                <label htmlFor="description" className="label">
                  {t('admin-dashboard-course-edit-description')}
                </label>
                <ReactQuill
                  theme="snow"
                  id="description"
                  value={value}
                  onChange={setValue}
                  placeholder={t('admin-dashboard-course-edit-description-placeholder')}
                />
              </div>

              <div className="w-full p-3">
                <label htmlFor="image1" className="label">
                  {t('admin-dashboard-course-edit-image')}
                </label>
                <input
                  type="file"
                  name="image1"
                  id="image1"
                  accept="image/jpeg, image/png"
                  onChange={handleFileChange}
                  className="form-control"
                />
                {imagePreview && (
                  <div className="mt-2">
                    <img
                      src={imagePreview}
                      alt="Product Preview"
                      className="w-full h-auto"
                    />
                  </div>
                )}

{imagePreview && (
                  <div className="mt-2">
                    <img
                      // src={imagePreview}
                      src={`${serverBaseURL}${imagePreview}`} 

                      alt="Product Preview"
                      className="w-full h-auto"
                    />
                  </div>
                )}
              </div>

              <div className="w-full p-3">
                <input
                  type="submit"
                  value={response.isLoading ? t('admin-dashboard-course-edit-loading') : t('admin-dashboard-course-edit-submit')}
                  disabled={response.isLoading}
                  className="btn btn-indigo"
                />
              </div>
            </div>
          </form>
        </div>
      ) : (
        <Spinner />
      )}
    </Wrapper>
  );
};

export default EditProduct;
