
// import {useEffect} from "react"
// import { Link, useParams } from "react-router-dom";
// import {useDispatch, useSelector} from "react-redux"
// import toast, { Toaster } from 'react-hot-toast';
// import { clearMessage } from "../../store/reducers/globalReducer";
// import Wrapper from "./Wrapper"
// import WrapperTeacher from "./WrapperTeacher"
// // import { useGetProductsQuery, useDeleteProductMutation } from "../../store/services/productService";
// import { useGetProductsQuery, useDeleteProductMutation ,useGetStudentsQuery} from "../../store/services/productServiceTeacher";
// import ScreenHeader from "../../components/ScreenHeader";
// import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
// import Spinner from "../../components/Spinner";
// import Pagination from "../../components/Pagination";

// const TeacherStudent = () => {
//    let {page} = useParams();
//    if(!page) {
//       page = 1;
//    }

//    const teacherId = localStorage.getItem('instructor-id'); // Assuming you store the teacher ID in local storage

//    const {data = [], isFetching} = useGetStudentsQuery({ page, teacherId });
//    console.log(data);
//    const {success} = useSelector(state => state.globalReducer);
//     const dispatch = useDispatch();
//     useEffect(() => {
//      if(success) {
//        toast.success(success);
//      }
//      return () => {
//         dispatch(clearMessage())
//      }
//     }, [])
//     const [delProduct, response] = useDeleteProductMutation();
    
//     const deleteProduct = id => {
//       if(window.confirm("Are you really want to delete this product?")) {
//           delProduct(id);
//       }
//     }
//     return(
//        <WrapperTeacher >
//          <ScreenHeaderTeacher>
//           <Link to="/dashboard/create-product" className="btn-dark">create Course</Link>
//           <Toaster position="top-right" />
//           </ScreenHeaderTeacher>
//           {!isFetching ? data?.students?.length > 0 ? <div>
//             <table className="w-full bg-gray-900 rounded-md">
//             <thead>
//                     <tr className="border-b border-gray-800 text-left">
//                     <th className="p-3 uppercase text-sm font-medium text-gray-500">Title</th>
//                                 <th className="p-3 uppercase text-sm font-medium text-gray-500">Price</th>
//                                 <th className="p-3 uppercase text-sm font-medium text-gray-500">Category</th>
//                                 <th className="p-3 uppercase text-sm font-medium text-gray-500">Image</th>
//                                 <th className="p-3 uppercase text-sm font-medium text-gray-500">Edit</th>
//                                 <th className="p-3 uppercase text-sm font-medium text-gray-500">Delete</th>
//                     </tr>
//                  </thead>
//                  <tbody>
//                   {data?.students?.map(product => (
//                      <tr className="odd:bg-gray-800" key={product._id}>
//                         <td className="p-3 capitalize text-sm font-normal text-gray-400">{product.name}</td>
//                         <td className="p-3 capitalize text-sm font-normal text-gray-400">${product.price}.00</td>
//                         <td className="p-3 capitalize text-sm font-normal text-gray-400">{product.category}</td>
                      
//                            <td className="p-3 capitalize text-sm font-normal text-gray-400">
//                                         <img src={`${product.thumbnail_url}`} alt="Product" className="w-20 h-20 rounded-md object-cover" />
//                                      </td>
//                                      <td className="p-3 capitalize text-sm font-normal text-gray-400"><Link to={`/dashboard/edit-product/${product._id}`} className="btn btn-warning">edit</Link></td>
//                                      <td className="p-3 capitalize text-sm font-normal text-gray-400"><Link to={`/dashboard/edit-video/${product._id}`} className="btn btn-warning">VIDEO</Link></td>
//                                      <td className="p-3 capitalize text-sm font-normal text-gray-400"><span className="btn btn-danger cursor-pointer" onClick={() => deleteProduct(product._id)}>delete</span></td>
//                      </tr>
//                   ))}
//                  </tbody>
//             </table>
//             <Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/teacher" />
//           </div> : 'No products!' : <Spinner />}
//        </WrapperTeacher>
//     )
// }
// export default TeacherStudent;













// import { useEffect } from "react";
// import { Link, useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import toast, { Toaster } from 'react-hot-toast';
// import { clearMessage } from "../../store/reducers/globalReducer";
// import WrapperTeacher from "./WrapperTeacher";
// import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
// import Spinner from "../../components/Spinner";
// import Pagination from "../../components/Pagination";
// import { useGetStudentsQuery, useDeleteProductMutation } from "../../store/services/productServiceTeacher";

// const TeacherStudent = () => {
//     let { page } = useParams();
//     if (!page) {
//         page = 1;
//     }

//     const teacherId = localStorage.getItem('instructor-id'); // Assuming you store the teacher ID in local storage

//     const { data = {}, isFetching } = useGetStudentsQuery({ page, teacherId });
//     console.log(data);
//     const { success } = useSelector(state => state.globalReducer);
//     const dispatch = useDispatch();
    
//     useEffect(() => {
//         if (success) {
//             toast.success(success);
//         }
//         return () => {
//             dispatch(clearMessage());
//         }
//     }, [success, dispatch]);

//     const [delProduct, response] = useDeleteProductMutation();
    
//     const deleteProduct = id => {
//         if (window.confirm("Are you really want to delete this product?")) {
//             delProduct(id);
//         }
//     }

//     return (
//         <WrapperTeacher>
//             <ScreenHeaderTeacher>
//                 <Link to="/dashboard/create-product" className="btn-dark">Create Course</Link>
//                 <Toaster position="top-right" />
//             </ScreenHeaderTeacher>
//             {!isFetching ? data.students?.length > 0 ? (
//                 <div>
//                     <table className="w-full bg-gray-900 rounded-md">
//                         <thead>
//                             <tr className="border-b border-gray-800 text-left">
//                                 <th className="p-3 uppercase text-sm font-medium text-gray-500">Name</th>
//                                 <th className="p-3 uppercase text-sm font-medium text-gray-500">Email</th>
//                                 <th className="p-3 uppercase text-sm font-medium text-gray-500">Total Paid Amount</th>
//                                 <th className="p-3 uppercase text-sm font-medium text-gray-500">Courses</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {data.students.map(student => (
//                                 <tr className="odd:bg-gray-800" key={student._id}>
//                                     <td className="p-3 capitalize text-sm font-normal text-gray-400">{student.name}</td>
//                                     <td className="p-3 capitalize text-sm font-normal text-gray-400">{student.email}</td>
//                                     <td className="p-3 capitalize text-sm font-normal text-gray-400">${student.total_paid_amount}.00</td>
//                                     <td className="p-3 capitalize text-sm font-normal text-gray-400">
//                                         <ul>
//                                             {student.paid_courses.map(course => (
//                                                 <li key={course.course_id}>{course.course_id} - ${course.amount_paid}</li>
//                                             ))}
//                                         </ul>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                     <Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/teacher" />
//                 </div>
//             ) : 'No students!' : <Spinner />}
//         </WrapperTeacher>
//     );
// }

// export default TeacherStudent;








import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { clearMessage } from "../../store/reducers/globalReducer";
import WrapperTeacher from "./WrapperTeacher";
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
import { useGetStudentsQuery } from "../../store/services/productServiceTeacher";

const TeacherStudent = () => {
    const { t } = useTranslation();

    let { page } = useParams();
    if (!page) {
        page = 1;
    }

    const teacherId = localStorage.getItem('instructor-id'); // Assuming you store the teacher ID in local storage

    const { data = {}, isFetching } = useGetStudentsQuery({ page, teacherId });
    const { success } = useSelector(state => state.globalReducer);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (success) {
            toast.success(success);
        }
        return () => {
            dispatch(clearMessage());
        }
    }, [success, dispatch]);

    const [selectedStudent, setSelectedStudent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (student) => {
        setSelectedStudent(student);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedStudent(null);
        setIsModalOpen(false);
    };

    return (
        <WrapperTeacher>
            <ScreenHeaderTeacher>
                <Link to="/dashboard/teacher" className="btn-dark">Back</Link>
                <Toaster position="top-right" />
            </ScreenHeaderTeacher>
            {!isFetching ? data.students?.length > 0 ? (
                <div>
                    <table className="w-full bg-yellow-500 rounded-md">
                        <thead>
                            <tr className="border-b border-gray-800 text-left">
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">
                                    {/* Name */}
                                    {t('dashboard-teacher-student-name')}
                                    </th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">
                                    {/* Email */}
                                    {t('dashboard-teacher-student-email')}
                                    </th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">
                                    {/* Total Amount Paid */}
                                    {t('dashboard-teacher-student-total-amount-paid')}
                                    </th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">
                                    {/* View Courses */}
                                    {t('dashboard-teacher-student-view-courses')}
                                    </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.students.map(student => (
                                <tr className="odd:bg-gray-800" key={student.studentId}>
                                    <td className="p-3 capitalize text-sm font-normal text-gray-400">{student.name}</td>
                                    <td className="p-3 capitalize text-sm font-normal text-gray-400">{student.email}</td>
                                    <td className="p-3 capitalize text-sm font-normal text-gray-400">${student.totalAmountPaidToTeacher}.00</td>
                                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                                        <button onClick={() => openModal(student)} className="btn btn-primary">
                                            {/* View Courses */}
                                            {t('dashboard-teacher-student-view-courses')}
                                            </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/teacher" />
                    
                    {/* Modal */}
                    {isModalOpen && selectedStudent && (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-yellow-600 p-6 rounded-md shadow-lg max-w-lg w-full h-1/2 max-h-screen overflow-y-auto">
            <h2 className="text-xl font-semibold mb-4">
                {/* Course Details for {selectedStudent.name} */}
                {t('dashboard-teacher-student-course-details', { studentName: selectedStudent.name })}
                
                </h2>
            <ul>
                {selectedStudent.detailedCourses.length > 0 ? (
                    selectedStudent.detailedCourses.map(course => (
                        <li key={course.courseId} className="mb-4 bg-white text-black p-4">
                            <div><strong>
                                {/* Course ID: */}
                                {t('dashboard-teacher-student-course-id')}:
                                </strong> {course.courseId}</div>
                            <div><strong>
                                {/* Amount Paid: */}
                                {t('dashboard-teacher-student-amount-paid')}:
                                </strong> ${course.amountPaid}</div>
                            <div><strong>
                                {/* Date of Payment: */}
                                {t('dashboard-teacher-student-date-of-payment')}:
                                </strong> {new Date(course.dateOfPayment).toLocaleDateString()}</div>
                            <div><strong>
                                {/* Access Expires At: */}
                                {t('dashboard-teacher-student-access-expires-at')}:
                                </strong> {new Date(course.accessExpiresAt).toLocaleDateString()}</div>
                        </li>
                    ))
                ) : (
                    // <p>No courses available for this student.</p>
                    <p>{t('dashboard-teacher-student-no-courses-available')}</p>

                )}
            </ul>
            <button onClick={closeModal} className="btn btn-secondary mt-4">
                
                {/* Close */}
                {t('dashboard-teacher-student-close')}
                </button>
        </div>
    </div>
)}

                    {/* {isModalOpen && selectedStudent && (
                        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
                            <div className="bg-yellow-500 p-6 rounded-md shadow-lg max-w-lg w-full ">
                                <h2 className="text-xl font-semibold mb-4">Course Details for {selectedStudent.name}</h2>
                                <ul>
                                    {selectedStudent.detailedCourses.length > 0 ? (
                                        selectedStudent.detailedCourses.map(course => (
                                            <li key={course.courseId} className="mb-4">
                                                <div><strong>Course ID:</strong> {course.courseId}</div>
                                                <div><strong>Amount Paid:</strong> ${course.amountPaid}</div>
                                                <div><strong>Date of Payment:</strong> {new Date(course.dateOfPayment).toLocaleDateString()}</div>
                                                <div><strong>Access Expires At:</strong> {new Date(course.accessExpiresAt).toLocaleDateString()}</div>
                                            </li>
                                        ))
                                    ) : (
                                        <p>No courses available for this student.</p>
                                    )}
                                </ul>
                                <button onClick={closeModal} className="btn btn-secondary mt-4">Close</button>
                            </div>
                        </div>
                    )} */}
                </div>
            ) : 'No students!' : <Spinner />}
        </WrapperTeacher>
    );
}

export default TeacherStudent;

