import React from 'react';
import { useTranslation } from 'react-i18next';
import HomeNav from './HomeNav';
import Footer from './FooterLms';

const AboutUs = () => {
  const { t } = useTranslation();

  return (

    <>
    <HomeNav />
 
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-[60px]">
      <h1 className="text-3xl font-bold text-center text-blue-600 mb-4">{t('aboutTitle')}</h1>
      <p className="text-lg text-gray-700 mb-4">{t('aboutDescription')}</p>
      <p className="text-lg text-gray-700 mb-8">{t('academyDescription')}</p>

      <div className="satisfaction-guarantee mb-8">
        <h2 className="text-2xl font-semibold text-blue-500 mb-2">{t('satisfactionGuarantee.title')}</h2>
        <p className="text-gray-600 mb-4">{t('satisfactionGuarantee.description')}</p>

        {t('satisfactionGuarantee.engagement', { returnObjects: true }).map((pack, index) => (
          <div key={index} className="engagement-pack bg-gray-100 p-4 rounded-md shadow mb-4">
            <h3 className="text-xl font-semibold text-blue-600">{pack.pack}</h3>
            <p className="text-gray-600">{pack.details}</p>
          </div>
        ))}

        <div className="how-it-works mt-6">
          <h3 className="text-xl font-semibold text-blue-500 mb-2">{t('satisfactionGuarantee.howItWorks.title')}</h3>
          <p className="text-gray-600">{t('satisfactionGuarantee.howItWorks.description')}</p>
        </div>
      </div>

      <p className="text-lg font-medium text-gray-800 text-center">{t('joinUs')}</p>
    </div>

    <Footer />
    
    </>




  );
};

export default AboutUs;
