import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';


const Headg = () => {
  const { t } = useTranslation();

  return (
    <section className="container mx-auto my-12 px-4">
      <div className="text-center">
        <Link 
        to={'/instructorregister'}
          className="inline-block"
        >
          <button 
            className="bg-[#E61F5B] text-white font-bold  py-4 px-6 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {/* Become a Teacher at Chinwi Academy */}
            {t('teacher-bottpm-home-upside-footer')}

          </button>
        </Link>
      </div>
    </section>
  );
};

export default Headg;
