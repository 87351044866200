






import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useGetTeachersQuery,
  useGetCoursesByTeacherIdQuery,
  useGetLecturesByCourseIdQuery,
  useGetCourseRandomQuery,
  useGetCategoriesQuery, // Import the new hook
  useGetProductsByCategoryQuery
} from '../../store/services/SupService';
import CoursesCardList from './CoursesCardList';
import Navs from "./HomeNav";
import Footer from './FooterLms';


const CourseSelection = () => {
  const { t } = useTranslation();

  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);


  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState(''); // "teacher", "subject", or "search"

  const { data: teachers, isLoading: teachersLoading, isError: teachersError } = useGetTeachersQuery();
  const { data: courses, isLoading: coursesLoading, isError: coursesError } = useGetCoursesByTeacherIdQuery(selectedTeacherId, { skip: !selectedTeacherId });
  const { data: allCoursesData, isLoading: allCoursesLoading, isError: allCoursesError } = useGetCourseRandomQuery();
  const { data: lectures, isLoading: lecturesLoading, isError: lecturesError } = useGetLecturesByCourseIdQuery(selectedCourseId, { skip: !selectedCourseId });
  const { data: categories, isLoading: categoriesLoading, isError: categoriesError } = useGetCategoriesQuery(); // Fetch categories

  const { data: products, isLoading, isError } = useGetProductsByCategoryQuery(selectedCategoryId, { skip: !selectedCategoryId });


  console.log("teachers",teachers)
  console.log("courses",courses)
  console.log("allCoursesData",allCoursesData)
  console.log("lectures",lectures)
  console.log("categories",categories)
  console.log("selectedCategory",selectedCategory)

  console.log("products",products)

  // Ensure allCourses is an array
  const allCourses = Array.isArray(allCoursesData) ? allCoursesData : [];

  const handleTeacherChange = (e) => {
    const value = e.target.value;
    setSelectedTeacherId(value);
    setSelectedCourseId(null);
    setSelectedCategory(''); // Reset category selection
    setSelectedCategoryId('');
    setSearchQuery(''); // Reset search query
    setActiveTab('teacher');
  };

  const handleCategoryChange = (e) => {
    setSelectedCategoryId(e.target.value);
    setSelectedCategory(e.target.value);
    setSelectedTeacherId(null); // Reset teacher selection
    setSelectedCourseId(null);
    setSearchQuery(''); // Reset search query
    setActiveTab('subject');
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setSelectedTeacherId(null); // Reset teacher selection
    setSelectedCourseId(null);
    setSelectedCategory(''); // Reset category selection
    setActiveTab('search');
  };

  const filteredCoursesByCategory = () => {
    if (selectedCategory.toLowerCase() === 'all') {
      return allCourses;
    }
    return allCourses.filter(course => course.category.toLowerCase() === selectedCategory.toLowerCase());
  };

  const filteredCoursesBySearch = () => {
    return allCourses.filter(course => course.name.toLowerCase().includes(searchQuery.toLowerCase()));
  };

  return (

<>
<Navs />
<div className="p-4 space-y-8 mt-[60px] bg-gray-200 min-h-[80vh] h-auto ">
      {/* Selection Options */}
      <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
        {/* Teacher Selection */}
        <div className="flex-1">
          <h2 className="text-lg font-bold mb-2">
            {/* Teacher Based */}
            {t('commoncore-top-menu-teacher')}

            </h2>
          {teachersLoading && <p>
            
            {/* Loading teachers... */}
            {t('commoncore-top-menu-teacher-loading')}

            </p>}
          {teachersError && <p>
            
            {/* Error loading teachers. */}
            {t('commoncore-top-menu-teacher-error')}

            </p>}
          {!teachersLoading && !teachersError && (
            <select 
              onChange={handleTeacherChange}
              className="p-2 border rounded-md w-full"
              defaultValue=""
            >
              <option value="" disabled>
                
                {/* Select a teacher */}
                {t('commoncore-top-menu-teacher-select')}

                </option>
              <option value="All">All Teachers</option>
              {teachers?.teachers.map((teacher) => (
                <option key={teacher._id} value={teacher._id}>{teacher.name}</option>
              ))}
            </select>
          )}
        </div>

    

<div className="flex-1">
          <h2 className="text-lg font-bold mb-2">
            
            {/* Subject Based */}
            {t('commoncore-top-menu-Subject')}

            </h2>
          {categoriesLoading && <p>
            
            {/* Loading Subject... */}
            {t('commoncore-top-menu-subject-loading')}

            </p>}
          {categoriesError && <p>
            
            {/* Error loading Subject. */}
            {t('commoncore-top-menu-Subject-error')}

            </p>}
          {!categoriesLoading && !categoriesError && (
            <select 
              onChange={handleCategoryChange}
              className="p-2 border rounded-md w-full"
              defaultValue=""
            >
              <option value="" disabled>
                
                {/* Select a Subject */}
                {t('commoncore-top-menu-subject-slect')}

                </option>
              <option value="All">All Subject</option>
              {teachers?.catagory.map((catagory) => (
                <option key={catagory._id} value={catagory.catagory}>{catagory.name}</option>
              ))}
            </select>
          )}
        </div>

        {/* Search Input */}
        <div className="flex-1">
          <h2 className="text-lg font-bold mb-2">
            
            {/* Search Based */}
            {t('commoncore-top-menu-Search')}

            </h2>
          <input
            type="text"
            // placeholder="Search for courses"
            placeholder={t('commoncore-top-menu-Search-placeholder')}
            value={searchQuery}
            onChange={handleSearchChange}
            className="p-2 border rounded-md w-full"
          />
        </div>
      </div>

      {/* Display Data */}
      <div>
        {/* Teacher Based Data */}
        {activeTab === 'teacher' && selectedTeacherId && (
          <>
            {coursesLoading && <p>Loading courses...</p>}
            {coursesError && <p>Error loading courses.</p>}
            {!coursesLoading && !coursesError && selectedTeacherId !== 'All' && courses?.length > 0 && (
              <CoursesCardList courses={courses} />
            )}
            {!coursesLoading && !coursesError && selectedTeacherId === 'All' && allCourses.length > 0 && (
              <CoursesCardList courses={allCourses} />
            )}
          </>
        )}

        {/* Subject Based Data */}
        {/* {activeTab === 'subject' && selectedCategory && (
          <>
            {allCoursesLoading && <p>Loading courses...</p>}
            {allCoursesError && <p>Error loading courses.</p>}
            {!allCoursesLoading && !allCoursesError && filteredCoursesByCategory()?.length > 0 && (
              <CoursesCardList courses={filteredCoursesByCategory()} />
            )}
          </>
        )} */}

            {activeTab === 'subject' && selectedCategoryId && (
          <>
            {isLoading && <p>Loading courses...</p>}
            {isError && <p>Error loading courses.</p>}
            {!isLoading && !isError && selectedCategoryId !== 'All' && products?.length > 0 && (
              <CoursesCardList courses={products} />
            )}
            {!isLoading && !isError && selectedCategoryId === 'All' && allCourses.length > 0 && (
              <CoursesCardList courses={products} />
            )}
          </>
        )}

        {/* Search Based Data */}
        {activeTab === 'search' && searchQuery && (
          <>
            {allCoursesLoading && <p>Loading courses...</p>}
            {allCoursesError && <p>Error loading courses.</p>}
            {!allCoursesLoading && !allCoursesError && filteredCoursesBySearch()?.length > 0 && (
              <CoursesCardList courses={filteredCoursesBySearch()} />
            )}
          </>
        )}
      </div>

      {/* Lectures */}
      {selectedCourseId && (
        <>
          {lecturesLoading && <p>Loading lectures...</p>}
          {lecturesError && <p>Error loading lectures.</p>}
          {!lecturesLoading && !lecturesError && lectures?.length > 0 && (
            <div className="p-4 border rounded-md">
              <h3 className="text-xl font-semibold mb-2">Lectures:</h3>
              <ul>
                {lectures.map((lecture) => (
                  <li key={lecture.id}>{lecture.name}</li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </div>
    <Footer />
</>

  
  );
};

export default CourseSelection;
