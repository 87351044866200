import {useEffect} from "react"
import { useTranslation } from 'react-i18next';  // Import the useTranslation hook

import {Link, useParams} from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import ScreenHeader from "../../components/ScreenHeader";
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import Wrapper from "./Wrapper"
import WrapperTeacher from "./WrapperTeacher"
import { clearMessage, setSuccess } from "../../store/reducers/globalReducer";
import { useGetQuery, useDeleteCategoryMutation } from "../../store/services/categoryServiceTeacher";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
const CategoriesTeacher = () => {
   const { t } = useTranslation();  // Initialize the translation hook

   let {page} = useParams();
   if(!page) {
      page = 1;
   }
    const {success} = useSelector(state => state.globalReducer);
    const dispatch = useDispatch();
    const {data = [], isFetching} = useGetQuery(page);
    const [removeCategory, response] = useDeleteCategoryMutation();
    console.log(data)


   //  const deleteCat = id => {
   //     if(window.confirm('Are you really want to delete the category?')) {
   //        removeCategory(id);
   //     }
   //  }
   //  useEffect(() => {
   //       if(response.isSuccess) {
   //          dispatch(setSuccess(response?.data?.message));
   //       }
   //  }, [response?.data?.message])

   const deleteCat = id => {
      if (window.confirm(t('dashboard-teacher-category-delete-confirmation'))) {
          removeCategory(id);
      }
  }

  useEffect(() => {
      if (response.isSuccess) {
          dispatch(setSuccess(t('dashboard-teacher-category-success-message')));
      }
  }, [response?.data?.message]);


    useEffect(() => {
     return () => {
        dispatch(clearMessage())
     }
    }, [])
    return(
       <WrapperTeacher>
           <ScreenHeaderTeacher>
              <Link to="/dashboard/teacher/create-category" className="btn-dark">
              {/* add categories */}
              {t('dashboard-teacher-category-add-categories')}
               <i className="bi bi-plus"></i></Link>
           </ScreenHeaderTeacher>
           {success && <div className="alert-success">{success}</div>}
           {!isFetching ? data?.categories?.length > 0 && <><div>
              <table className="w-full bg-gray-900 rounded-md">
                 <thead>
                    <tr className="border-b border-gray-800 text-left">
                       <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* name */}
                        {t('dashboard-teacher-category-name')}
                        </th>
                       <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* edit */}
                        {t('dashboard-teacher-category-edit')}

                        </th>
                       <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* delete */}
                        {t('dashboard-teacher-category-delete')}

                        </th>
                    </tr>
                 </thead>
                 <tbody>
                    {data?.categories?.map(category => (
                       <tr key={category._id} className="odd:bg-gray-800">
                          <td className="p-3 capitalize text-sm font-normal text-gray-400">{category.name}</td>
                          <td className="p-3 capitalize text-sm font-normal text-gray-400"><Link to={`/dashboard/teacher/update-category/${category._id}`} className="btn btn-warning">
                          {/* edit */}
                          {t('dashboard-teacher-category-edit')}
                          </Link></td>
                          <td className="p-3 capitalize text-sm font-normal text-gray-400"><button className="btn btn-danger" onClick={() => deleteCat(category._id)}>
                           {/* delete */}
                           
                           {t('dashboard-teacher-category-delete')}

                           </button></td>
                       </tr>
                    ))}
                 </tbody>
              </table>
           </div><Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/categories" /></> : <Spinner />}
       </WrapperTeacher>
    )
}
export default CategoriesTeacher;