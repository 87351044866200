// import React from 'react';

// const CoursesCardList = ({ courses }) => {
//   return (
//     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
//       {courses.map((course) => (
//         <div key={course.id} className="bg-white p-4 rounded-lg shadow-md">
//           <h3 className="text-lg font-semibold">{course.name}</h3>
//           <p className="text-gray-600">{course.description}</p>
//           <p className="text-gray-600"><strong>Category:</strong> {course.category}</p>
//           <p className="text-gray-600"><strong>Duration:</strong> {course.duration} hours</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default CoursesCardList;


import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

const apiimage = process.env.REACT_APP_API_PICTURE;


const CoursesCardList = ({ courses }) => {
  const { t } = useTranslation();

  // const serverBaseURL = 'https://api.wgktrade.com'; // Change this as needed
  const serverBaseURL = apiimage

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {courses.map((course) => (
        <Link 
          key={course._id} 
          to={`/coursesmainsingle/${course._id}`} 
          className="block"
        >
          <div className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-1 transition duration-300">
            {/* Course Thumbnail */}
            {course.thumbnail_url && (
              <img 
              src={`${serverBaseURL}${course.thumbnail_url}`}
                // src={course.thumbnail_url} 
                alt={course.title} 
                className="w-full h-48 object-cover rounded-t-lg mb-4" 

                // className="w-full object-cover"
                // style={{ height: 'auto', aspectRatio: '16/9' }}
              />
            )}
            {/* Course Title */}
            <h3 className="text-lg font-semibold">{course.title}</h3>
            <p className="text-gray-600 mb-4" dangerouslySetInnerHTML={{ __html: course.description }}></p>

            {/* Price Section */}
            <div className="flex justify-between items-center mt-4">
              <p className="text-green-500 font-bold">{t('price')} : {course.price}</p>
              <p className="text-gray-500 line-through">{t('price')}: {course.price + 50}</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default CoursesCardList;
