
import {useEffect} from "react"
import { useTranslation } from 'react-i18next';

import { Link, useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import toast, { Toaster } from 'react-hot-toast';
import { clearMessage } from "../../store/reducers/globalReducer";
import Wrapper from "./Wrapper"
import WrapperTeacher from "./WrapperTeacher"
// import { useGetProductsQuery, useDeleteProductMutation } from "../../store/services/productService";
import { useGetCoursesAllAdminQuery , useDeleteProductMutation } from "../../store/services/productService";
import ScreenHeader from "../../components/ScreenHeader";
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
const apiimage = process.env.REACT_APP_API_PICTURE;
const TeacherPanel = () => {
   const { t } = useTranslation();

   let {page} = useParams();
   if(!page) {
      page = 1;
   }


   const {data = [], isFetching} = useGetCoursesAllAdminQuery ({ page });
   // const serverBaseURL = 'https://api.wgktrade.com'; // Change this as needed

   const serverBaseURL = apiimage;
   console.log(data);
   const {success} = useSelector(state => state.globalReducer);
    const dispatch = useDispatch();
    useEffect(() => {
     if(success) {
       toast.success(success);
     }
     return () => {
        dispatch(clearMessage())
     }
    }, [])
    const [delProduct, response] = useDeleteProductMutation();
    
    const deleteProduct = id => {
      // if(window.confirm("Are you really want to delete this product?")) {
      //     delProduct(id);
      // }

      if (window.confirm(t('admin-dashboard-course-confirm-delete'))) {
         delProduct(id);
       }
    }
    return(
       <Wrapper >
         <ScreenHeader>
          {/* <Link to="/" className="btn-dark">Well Come To Couses</Link> */}
          <Link to="/" className="btn-dark">{t('admin-dashboard-course-welcome')}</Link>

          <Toaster position="top-right" />
          </ScreenHeader>
          {!isFetching ? data?.products?.length > 0 ? <div>
            <table className="w-full bg-gray-900 rounded-md">
            <thead>
                    {/* <tr className="border-b border-gray-800 text-left">
                    <th className="p-3 uppercase text-sm font-medium text-gray-500">Title</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Price</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Category</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Image</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Edit</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Delete</th>
                    </tr> */}

<tr className="border-b border-gray-800 text-left">
                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-course-title')}</th>
                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-course-price')}</th>
                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-course-category')}</th>
                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-course-image')}</th>
                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-course-edit')}</th>
                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-course-video')}</th>
                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-course-delete')}</th>
              </tr>
                 </thead>
                 <tbody>
                  {data?.products?.map(product => (
                     <tr className="odd:bg-gray-800" key={product._id}>
                        <td className="p-3 capitalize text-sm font-normal text-gray-400">{product.title}</td>
                        <td className="p-3 capitalize text-sm font-normal text-gray-400">${product.price}.00</td>
                        <td className="p-3 capitalize text-sm font-normal text-gray-400">{product.category}</td>
                      
                           <td className="p-3 capitalize text-sm font-normal text-gray-400">
                           

                                        <img    src={`${serverBaseURL}${product.thumbnail_url}`} alt="Product" className="w-20 h-20 rounded-md object-cover" />
                                     </td>
{/*                                      
                                     <td className="p-3 capitalize text-sm font-normal text-gray-400"><Link to={`/dashboard/update/admin/course/${product._id}`} className="btn btn-warning">edit</Link></td>
                                     <td className="p-3 capitalize text-sm font-normal text-gray-400"><Link to={`/dashboard/edit-video/${product._id}`} className="btn btn-warning">VIDEO</Link></td>
                                     <td className="p-3 capitalize text-sm font-normal text-gray-400"><span className="btn btn-danger cursor-pointer" onClick={() => deleteProduct(product._id)}>delete</span></td> */}
                     
                     
                     <td className="p-3 capitalize text-sm font-normal text-gray-400"><Link to={`/dashboard/update/admin/course/${product._id}`} className="btn btn-warning">{t('admin-dashboard-course-edit')}</Link></td>
                  <td className="p-3 capitalize text-sm font-normal text-gray-400"><Link to={`/dashboard/edit-video/${product._id}`} className="btn btn-warning">{t('admin-dashboard-course-video')}</Link></td>
                  <td className="p-3 capitalize text-sm font-normal text-gray-400"><span className="btn btn-danger cursor-pointer" onClick={() => deleteProduct(product._id)}>{t('admin-dashboard-course-delete')}</span></td>
                     
                     </tr>
                  ))}
                 </tbody>
            </table>
            <Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/get/admin/course" />
          </div> : 'No Courses!' : <Spinner />}
       </Wrapper>
    )
}
export default TeacherPanel;