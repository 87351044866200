// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useSearchCoursesQuery }  from "../../store/services/productService"; // Import the search hook
// import Spinner from "../../components/Spinner";
// import toast, { Toaster } from 'react-hot-toast';
// import { clearMessage } from "../../store/reducers/globalReducer";
// import Wrapper from "./Wrapper";
// import ScreenHeader from "../../components/ScreenHeader";
// import { Link } from "react-router-dom";

// const Courses = () => {
//   const [searchFields, setSearchFields] = useState({ course_title: "", course_id: "" });
//   const [searchTerm, setSearchTerm] = useState(""); // Combined search term
//   const { data = [], isFetching } = useSearchCoursesQuery(searchTerm, { skip: !searchTerm });
//   const { success } = useSelector(state => state.globalReducer);
//   const dispatch = useDispatch();
// console.log("data",data)
//   useEffect(() => {
//     if (success) {
//       toast.success(success);
//     }
//     return () => {
//       dispatch(clearMessage());
//     };
//   }, [success, dispatch]);

//   const handleSearch = () => {
//     const queryParams = new URLSearchParams(
//       Object.fromEntries(
//         Object.entries(searchFields).filter(([key, value]) => value.trim() !== '')
//       )
//     ).toString();
    
//     setSearchTerm(queryParams);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setSearchFields(prev => ({ ...prev, [name]: value }));
//   };

//   return (
//     <Wrapper>
//               <ScreenHeader>
//                 <Link to="/dashboard/create-student" className="btn-dark">Create Student</Link>
//                 <p className="bg-yellow-500 p-4 mt-3 text-black font-bold">Course Search</p>

//                 <Toaster position="top-right" />
//             </ScreenHeader>
//     <div>
//       <Toaster position="top-right" />
//       <div className="flex space-x-4 mb-4">
//         <input
//           type="text"
//           name="course_title"
//           placeholder="Course Title"
//           value={searchFields.course_title}
//           onChange={handleChange}
//           className="p-2 rounded"
//         />
//         <input
//           type="text"
//           name="course_id"
//           placeholder="Course ID"
//           value={searchFields.course_id}
//           onChange={handleChange}
//           className="p-2 rounded"
//         />
//         <button
//           onClick={handleSearch}
//           className="btn btn-primary hover:bg-red-500 bg-blue-500"
//         >
//           Search
//         </button>
//       </div>

//       {!isFetching ? (
//         data.courses?.length > 0 ? (
//           <table className="w-full bg-gray-900 rounded-md">
//             <thead>
//               <tr className="border-b border-gray-800 text-left">
//                 <th className="p-3 uppercase text-sm font-medium text-gray-500">Title</th>
//                 <th className="p-3 uppercase text-sm font-medium text-gray-500">Instructor</th>
//                 <th className="p-3 uppercase text-sm font-medium text-gray-500">Description</th>
//                 <th className="p-3 uppercase text-sm font-medium text-gray-500">Videos</th>
//               </tr>
//             </thead>
//             <tbody>
//               {data.courses.map(course => (
//                 <tr className="odd:bg-gray-800" key={course._id}>
//                   <td className="p-3 text-sm font-normal text-gray-400">{course.title}</td>
//                   <td className="p-3 text-sm font-normal text-gray-400">{course.instructor_id.name}</td>
//                   <td className="p-3 text-sm font-normal text-gray-400">{course.description}</td>
//                   <td className="p-3 text-sm font-normal text-gray-400">
//                     {course.video_list.map(video => (
//                       <div key={video._id}>{video.title}</div>
//                     ))}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         ) : <p className="bg-red-500 p-4">No courses found!</p>
//       ) : <Spinner />}
//     </div>
//     </Wrapper>
//   );
// };

// export default Courses;













import { clearMessage } from "../../store/reducers/globalReducer";
import { useTranslation } from 'react-i18next'; // Import useTranslation

import Wrapper from "./Wrapper";
import ScreenHeader from "../../components/ScreenHeader";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchCoursesQuery } from "../../store/services/productService"; // Assuming you have an API hook for searching courses
import toast, { Toaster } from "react-hot-toast";
import Spinner from "../../components/Spinner"; // Assuming you have a Spinner component for loading

const Courses = () => {
  const { t } = useTranslation(); // Initialize useTranslation hook

  const [searchFields, setSearchFields] = useState({ course_title: "", course_id: "" });
  const [searchTerm, setSearchTerm] = useState(""); // Combined search term
  const { data = [], isFetching } = useSearchCoursesQuery(searchTerm, { skip: !searchTerm });
  const { success } = useSelector(state => state.globalReducer);
  const dispatch = useDispatch();
console.log("data",data)
  useEffect(() => {
    if (success) {
      toast.success(success);
    }
    return () => {
      dispatch(clearMessage());
    };
  }, [success, dispatch]);

  const handleSearch = () => {
    const queryParams = new URLSearchParams(
      Object.fromEntries(
        Object.entries(searchFields).filter(([key, value]) => value.trim() !== '')
      )
    ).toString();
    
    setSearchTerm(queryParams);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchFields(prev => ({
      ...prev,
      [name]: value,
      ...(name === 'course_title' ? { course_id: '' } : { course_title: '' })
    }));
  };

  return (
    <Wrapper>
      <ScreenHeader>
        {/* <Link to="/dashboard/create-student" className="btn-dark">Create Student</Link> */}
        <p className="bg-yellow-500 p-4 mt-3 text-black font-bold">
          {/* Course Search */}
          {t('admin-dashboard-course-search-title')}
          </p>
        <Toaster position="top-right" />
      </ScreenHeader>
      <div>
        <Toaster position="top-right" />
        <div className="flex space-x-4 mb-4 text-black">
          <input
            type="text"
            name="course_title"
            // placeholder="Course Title"
            placeholder={t('admin-dashboard-course-search-search-title')}
            value={searchFields.course_title}
            onChange={handleChange}
            className="p-2 rounded"
          />
          <input
            type="text"
            name="course_id"
            // placeholder="Course ID"
            placeholder={t('admin-dashboard-course-search-search-id')}
            value={searchFields.course_id}
            onChange={handleChange}
            className="p-2 rounded"
          />
          <button
            onClick={handleSearch}
            className="btn btn-primary hover:bg-red-500 bg-blue-500"
          >
            {/* Search */}
           {t('admin-dashboard-course-search-search-button')}

          </button>
        </div>

        {!isFetching ? (
          data.courses?.length > 0 ? (
            <>
               <table className="w-full bg-gray-900 rounded-md">
              <thead>
                <tr className="border-b border-gray-800 text-left">
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    {/* Title */}
                    {t('admin-dashboard-course-search-table-title')}

                    </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    {/* Instructor */}
                    {t('admin-dashboard-course-search-table-instructor')}

                    </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    {/* Description */}
                    {t('admin-dashboard-course-search-table-description')}

                    </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    {/* Videos */}
                    {t('admin-dashboard-course-search-table-videos')}

                    </th>
                </tr>
              </thead>
              <tbody>
                {data.courses.map(course => (
                  <tr className="odd:bg-gray-800" key={course._id}>
                    <td className="p-3 text-sm font-normal text-gray-400">{course.title}</td>
                    <td className="p-3 text-sm font-normal text-gray-400">{course.instructor_id.name}</td>
                    <td className="p-3 text-sm font-normal text-gray-400">{course.description}</td>
                    <td className="p-3 text-sm font-normal text-gray-400">
                      {course.video_list.map(video => (
                        <div key={video._id}>{video.title}</div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>


          
            </>
         

            
          ) : <p className="bg-red-500 p-4">
                    {t('admin-dashboard-course-search-no-courses')}
          </p>
        ) : <Spinner />}
      </div>
    </Wrapper>
  );
};

export default Courses;

