import {Link, useNavigate, useParams} from "react-router-dom"
import { useTranslation } from "react-i18next";

import {useState, useEffect} from "react"
import { useDispatch } from "react-redux"
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper"
import WrapperTeacher from "./WrapperTeacher"
import { setSuccess } from "../../store/reducers/globalReducer";
import { useFetchCategoryQuery, useUpdateCategoryMutation } from "../../store/services/categoryServiceTeacher";
import Spinner from "../../components/Spinner";
const UpdateCategory = () => {
    const { t } = useTranslation(); // Using the useTranslation hook

    const [state, setState] = useState('');
    const {id} = useParams();
    const {data, isFetching} = useFetchCategoryQuery(id);
    console.log('category data: ',data)
    useEffect(() => {
        data?.category && setState(data?.category?.name);
    }, [data?.category])
    const [saveCategory, response] = useUpdateCategoryMutation();
    console.log(response)
     const errors = response?.error?.data?.errors ? response?.error?.data?.errors : [];
    const updateSubmit = e => {
       e.preventDefault();
       saveCategory({name: state, id});
   }
   const navigate = useNavigate();
   const dispatch = useDispatch();
   useEffect(() => {
       if(response?.isSuccess) {
           dispatch(setSuccess(response?.data?.message));
          navigate('/dashboard/tcategories');
       }
   }, [response?.isSuccess])
    return(
       <WrapperTeacher>
           <ScreenHeaderTeacher>
              <Link to="/dashboard/teacher/tcategories" className="btn-dark"><i className="bi bi-arrow-left-short"></i>
               {/* categories list */}
               {t("dashboard-teacher-updateCategory-backToCategoriesList")}
               </Link>
           </ScreenHeaderTeacher>
           {!isFetching ? <form className="w-full md:w-8/12" onSubmit={updateSubmit}>
               <h3 className="text-lg capitalize mb-3">
                {/* Update category */}
                {t("dashboard-teacher-updateCategory-updateCategory")}
                </h3>
               {errors.length > 0 && errors.map((error, key) => (
                   <p className="alert-danger" key={key}>{error.msg}</p>
               ))}
               <div className="mb-3">
                   {/* <input type="text" name="" className="form-control" placeholder="Category Name..." value={state} onChange={(e) => setState(e.target.value)} /> */}
                   <input type="text" name="" className="form-control"  placeholder={t("dashboard-teacher-updateCategory-categoryNamePlaceholder")} value={state} onChange={(e) => setState(e.target.value)} />
               </div>
               <div className="mb-3">
                   {/* <input type="submit" value='Update' className="btn btn-indigo" /> */}
                   <input type="submit" value={t("dashboard-teacher-updateCategory-updateButton")} className="btn btn-indigo" />
               </div>
           </form> : <Spinner />}
       </WrapperTeacher>
    )
}
export default UpdateCategory;