import {Link, useNavigate} from "react-router-dom"
import { useTranslation } from 'react-i18next';  // Import the useTranslation hook

import {useState, useEffect} from "react"
import { useDispatch } from "react-redux"
import ScreenHeader from "../../components/ScreenHeader";
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import Wrapper from "./Wrapper"
import WrapperTeacher from "./WrapperTeacher"
import { useCreateMutation } from "../../store/services/categoryServiceTeacher";
import { setSuccess } from "../../store/reducers/globalReducer";


const CreatCategoryTeacher = () => {
    const { t } = useTranslation();  // Initialize the translation hook

    const [state, setState] = useState('');
    const [saveCategory, data] = useCreateMutation();
    console.log(data)
    const errors = data?.error?.data?.errors ? data?.error?.data?.errors : [];
    const submitCategory = e => {
       e.preventDefault();
       saveCategory({name: state});
   }
   const navigate = useNavigate();
   const dispatch = useDispatch();


//    useEffect(() => {
//        if(data?.isSuccess) {
//            dispatch(setSuccess(data?.data?.message));
//           navigate('/dashboard/teacher/tcategories');
//        }
//    }, [data?.isSuccess])

useEffect(() => {
    if (data?.isSuccess) {
        dispatch(setSuccess(t('dashboard-teacher-createcategory-success-message')));
        navigate('/dashboard/teacher/tcategories');
    }
}, [data?.isSuccess]);


    return(
       <WrapperTeacher>
           <ScreenHeaderTeacher>
              <Link to="/dashboard/teacher/tcategories" className="btn-dark"><i className="bi bi-arrow-left-short"></i>
              
               {/* categories list */}
               {t('dashboard-teacher-createcategory-categories-list')}
               
               </Link>
           </ScreenHeaderTeacher>
           <form className="w-full md:w-8/12" onSubmit={submitCategory}>
               <h3 className="text-lg capitalize mb-3">
                {/* create category */}
                {t('dashboard-teacher-createcategory-create-category')}
                </h3>
               {errors.length > 0 && errors.map((error, key) => (
                   <p className="alert-danger" key={key}>{error.msg}</p>
               ))}
               <div className="mb-3">
                   <input type="text" name="" className="form-control" 
                //    placeholder="Category Name..."
                placeholder={t('dashboard-teacher-createcategory-category-name-placeholder')} 

                   value={state} onChange={(e) => setState(e.target.value)} 
                   />
               </div>
               <div className="mb-3">
                   <input type="submit" 
                //    value={data.isLoading ? 'loading...' : 'create category'} 
                   value={data.isLoading ? t('dashboard-teacher-createcategory-loading') : t('dashboard-teacher-createcategory-create')} 

                   className="btn-indigo" />
               </div>
           </form>
       </WrapperTeacher>
    )
}
export default CreatCategoryTeacher;