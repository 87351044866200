// // src/components/CourseDetail.js
// import React from 'react';
// import { useParams } from 'react-router-dom';
// import { useGetCourseByIdQuery } from '../../store/services/CourseServiceMain';
// import HomeNav from './HomeNav';



// const CourseDetail = () => {
//   const { id } = useParams();
//   const { data: course, error, isLoading } = useGetCourseByIdQuery(id);
//   console.log('Course not id',id)
//   console.log('Course not error',error)
//   console.log('Course not course',course)


//   if (isLoading) return <div>Loading...</div>;
//   if (error) return <div>Error fetching course details.</div>;

//   return (
//     <>
//     <HomeNav />
//     <div className="p-4 mt-[96px]">
//       <div className="max-w-3xl mx-auto bg-white rounded-lg shadow p-6">
//         <img src={course.thumbnail_url} alt={course.title} className="w-full h-64 object-cover rounded-t-lg" />
//         <div className="p-4">
//           <h2 className="text-3xl font-semibold mb-4">{course.title}</h2>
//           <p className="text-lg text-gray-700 mb-4">{course.description}</p>
//           <p className="text-gray-600">Category: {course.category}</p>
//           <p className="text-gray-600">Price: ${course.price}</p>
//         </div>
//       </div>
//     </div>
//     </>

//   );
// };

// export default CourseDetail;


// src/components/CourseDetail.js





// import React from 'react';
// import { useParams } from 'react-router-dom';
// import { useGetCourseByIdQuery } from '../../store/services/CourseServiceMain';
// import HomeNav from './HomeNav';

// const CourseDetail = () => {
//   const { id } = useParams();
//   const { data: course, error, isLoading } = useGetCourseByIdQuery(id);

//   if (isLoading) return <div>Loading...</div>;
//   if (error) return <div>Error fetching course details.</div>;

//   return (
//     <>
//       <HomeNav />
//       <div className="p-4 mt-[96px]">
//         <div className="max-w-3xl mx-auto bg-white rounded-lg shadow p-6">
//           <img src={course.thumbnail_url} alt={course.title} className="w-full h-64 object-cover rounded-t-lg" />
//           <div className="p-4">
//             <h2 className="text-3xl font-semibold mb-4">{course.title}</h2>
//             <p className="text-lg text-gray-700 mb-4">{course.description}</p>
//             <p className="text-gray-600">Category: {course.category}</p>
//             <p className="text-gray-600">Price: ${course.price}</p>
//           </div>

//           {/* Video List */}
//           <div className="mt-6">
//             <h3 className="text-2xl font-semibold mb-4">Videos</h3>
//             {course.video_list && course.video_list.length > 0 ? (
//               course.video_list.map((video) => (
//                 <div key={video._id} className="flex justify-between items-center mb-4 p-4 border rounded hover:bg-gray-100">
//                   <div className="flex items-center">
//                     <i className="fas fa-video mr-2"></i>
//                     <span>{video.title}</span>
//                   </div>
//                   <button 
//                     className={`px-3 py-1 rounded ${video.is_free ? 'bg-green-500' : 'bg-red-500'} text-white`}
//                     onClick={() => {
//                       // Handle the button click, passing the course and video IDs
//                       console.log(`Course ID: ${course._id}, Video ID: ${video._id}`);
//                     }}
//                   >
//                     {video.is_free ? 'Free' : 'Paid'}
//                   </button>
//                 </div>
//               ))
//             ) : (
//               <p className="text-gray-500">No videos available for this course.</p>
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CourseDetail;



// src/components/CourseDetail.js








// import React from 'react';
// import { useParams } from 'react-router-dom';
// import { useGetCourseByIdQuery } from '../../store/services/CourseServiceMain';
// import HomeNav from './HomeNav';

// const CourseDetail = () => {
//   const { id } = useParams();
//   const { data: course, error, isLoading } = useGetCourseByIdQuery(id);
//   console.log("course",course)

//   if (isLoading) return <div className="p-4">Loading...</div>;
//   if (error) return <div className="p-4">Error fetching course details.</div>;

//   return (
//     <>
//       <HomeNav />
//       <div className="p-4 mt-[96px]">
//         <div className="max-w-4xl mx-auto bg-white rounded-lg shadow p-6 mb-6">
//           <img src={course.thumbnail_url} alt={course.title} className="w-full h-64 object-cover rounded-t-lg" />
//           <div className="p-4">
//             <h2 className="text-3xl font-semibold mb-4">{course.title}</h2>
//             <p className="text-lg text-gray-700 mb-4">{course.description}</p>
//             <p className="text-gray-600 mb-2">Category: {course.category}</p>
//             <p className="text-gray-600">Price: ${course.price}</p>
//           </div>
//         </div>

//         {/* Video List */}
//         <div className="max-w-4xl mx-auto bg-white rounded-lg shadow p-6">
//           <h3 className="text-2xl font-semibold mb-4">Videos</h3>
//           {course.video_list && course.video_list.length > 0 ? (
//             course.video_list.map((video) => (
//               <div key={video._id} className="flex items-center mb-4 p-4 border rounded hover:bg-gray-100">
//                 <i className={`fas fa-video text-blue-500 mr-3`}></i> {/* Video icon */}
//                 <div className="flex-1">
//                   <span className="text-lg">{video.title}</span>
//                 </div>
//                 <button 
//                   className={`px-3 py-1 rounded ${video.is_free ? 'bg-green-500' : 'bg-red-500'} text-white`}
//                   onClick={() => {
//                     // Handle the button click, passing the course and video IDs
//                     console.log(`Course ID: ${course._id}, Video ID: ${video._id}`);
//                   }}
//                 >
//                   {video.is_free ? 'Free' : 'Paid'}
//                 </button>
//               </div>
//             ))
//           ) : (
//             <p className="text-gray-500">No videos available for this course.</p>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default CourseDetail;




// src/components/CourseDetail.js
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate, useParams } from 'react-router-dom';
import { useGetCourseByIdQuery } from '../../store/services/CourseServiceMain';
import HomeNav from './HomeNav';
import Footer from './FooterLms';
const apiimage = process.env.REACT_APP_API_PICTURE;

const CourseDetail = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const { data: course, error, isLoading } = useGetCourseByIdQuery(id);
  console.log("course", course);
  // const serverBaseURL = 'https://api.wgktrade.com'; // Change this as needed
  const serverBaseURL = apiimage; // Change this as needed

  const navigate = useNavigate()
  if (isLoading) return (
    <>
      <HomeNav />
      <div className="p-4 min-h-[80vh] h-auto mt-[96px] ">
        <p className='bg-green-600 text-white p-4'>
          {/* Loading ........... */}
          
          {t('coursemain-single-loading')}
          </p>

      </div>
      <Footer />
    </>
  );
  if (error) return (
    <>
      <HomeNav />
      <div className="p-4 min-h-[80vh] h-auto mt-[96px] ">
        <p className='bg-red-600 text-white p-4'>
          {/* Error fetching course details. */}
          {t('coursemain-single-error-fetching')}
          </p>

      </div>
      <Footer />
    </>
  );

  const handleVideoClick = (courseId, videoId) => {
    console.log(`Course ID: ${courseId}, Video ID: ${videoId}`);
    // Add your logic here, e.g., navigating to the video page or displaying the video
    navigate(`/video/${course._id}/${videoId}`)
  };

  return (
    <>
      <HomeNav />
      <div className="p-4 mt-[96px]  min-h-[80vh] h-auto">
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow p-6 mb-6">
          <img src={`${serverBaseURL}${course.thumbnail_url}`} alt={course.title} className="w-full h-64 object-cover rounded-t-lg" />
          <div className="p-4">
            <h2 className="text-3xl font-semibold mb-4">{course.title}</h2>
            <p className="text-lg text-gray-700 mb-4">{course.description}</p>
            {/* <p className="text-gray-600 mb-2">Category: {course.category}</p> */}
            <p className="text-gray-600 mb-2">{t('coursemain-single-category')} {course.category}</p>
            <p className="text-gray-600">
            {/* Price: ${course.price}</p> */}
            {t('coursemain-single-price')}:{course.price}</p>
            </div>
        </div>

        {/* Video List */}
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow p-6">
          <h3 className="text-2xl font-semibold mb-4">
            {/* Videos */}
            
            {t('coursemain-single-videos')}
            </h3>
          {course.video_list && course.video_list.length > 0 ? (
            course.video_list.map((video) => (
              <div
                key={video._id}
                className="flex items-center mb-4 p-4 border rounded hover:bg-gray-100 cursor-pointer"
                onClick={() => handleVideoClick(course._id, video._id)} // Make the div clickable
              >
                <i className="fas fa-video text-blue-500 mr-3"></i>
                <div className="flex-1">
                  <span className="text-lg">{video.title}</span>
                </div>
                <button
                  className={`px-3 py-1 rounded ${video.is_free ? 'bg-green-500' : 'bg-red-500'} text-white`}
                >
                  {/* {video.is_free ? 'Free' : 'Paid'} */}
                  {video.is_free ? t('coursemain-single-paid') : t('coursemain-single-free')}
                </button>
              </div>
            ))
          ) : (
            <p className="text-gray-500">
              {/* No videos available for this course. */}
              {t('coursemain-single-no-videos-available')}
              </p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CourseDetail;


