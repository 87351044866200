import React from 'react';
import { useTranslation } from 'react-i18next';
import HomeNav from './HomeNav';
import Footer from './FooterLms';
import i18n from 'i18next';

const ContactUs = () => {
  const { t, i18n } = useTranslation(); // i18n gives access to the current language

  return (


    <>

    <HomeNav />
     <div className="bg-[#f5f5f8]  mt-[60px] min-h-screen flex flex-col items-center p-4">
      {/* Top Section with Contact Details */}
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mb-6 text-center">
        <h2 className="text-xl font-bold mb-4">{t('contact.details_title')}</h2>
        <div className="mb-2">
          <span className="font-semibold">{t('contact.address')}:</span> Damas Road, Missour City, Morocco
        </div>
        <div className="mb-2">
          <span className="font-semibold">{t('contact.email')}:</span> Chinwiacademy@gmail.com
        </div>
        <div className="mb-2">
          {/* <span className="font-semibold">{t('contact.phone')}:</span> +212 60 193 8528 */}


          {i18n.language === 'ar' ? (

<>
                  <span className="ltr-phone" style={{ direction:"ltr", unicodeBidi:'bidi-override'}}> : +212 60 133 8528</span>

        {t('footer-phone')} 


</>
) : (
  <>
                    {t('footer-phone')} 

  </>
)}




        </div>
      </div>

      {/* Contact Form */}
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        <h1 className="text-2xl font-bold mb-6 text-center">{t('contact.title')}</h1>
        <form>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">{t('contact.name')}</label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              placeholder={t('contact.name_placeholder')}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">{t('contact.email')}</label>
            <input
              type="email"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              placeholder={t('contact.email_placeholder')}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">{t('contact.subject')}</label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              placeholder={t('contact.subject_placeholder')}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">{t('contact.message')}</label>
            <textarea
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              placeholder={t('contact.message_placeholder')}
              rows="4"
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
          >
            {t('contact.submit')}
          </button>
        </form>
      </div>
    </div>
    <Footer />
    </>
   
  );
};

export default ContactUs;
