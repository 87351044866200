
// import React, { useEffect, useState } from 'react';
// import { useGetPackagesQuery } from '../../store/services/productService'; // Adjust import path
// import Wrapper from './Wrapper'; // Adjust import path
// import ScreenHeader from '../../components/ScreenHeader'; // Adjust import path
// import { Navigate, useNavigate } from 'react-router-dom';

// const PackageList = () => {
//   const { data: packages, error, isLoading } = useGetPackagesQuery();
//   const navigate= useNavigate()
// console.log("packages",packages)
//   if (isLoading) return <p>Loading...</p>;

//   if (error) {
//     return (
//       <p className="text-red-600">
//         {error.data?.msg || 'Error fetching packages'}
//       </p>
//     );
//   }

//   const handleEdit = (packageId) => {
//     // Implement edit functionality
//     navigate(`/dashboard/update/admin/package/${packageId}`)
//     console.log('Edit package:', packageId);
//   };

//   const handleDelete = (packageId) => {
//     // Implement delete functionality
//     console.log('Delete package:', packageId);
//   };

//   return (
//     <Wrapper>
//       <div className="container mx-auto p-4 bg-yellow-400 text-black">
//         <ScreenHeader title="Packages List" />
//         <div className="flex flex-wrap -mx-2">
//           <div className="w-full lg:w-8/12 px-2">
//             {packages && packages.length > 0 ? (
//               <ul className="space-y-4">
//                 {packages.map(pkg => (
//                   <li key={pkg._id} className="p-4 bg-white shadow-md rounded-md">
//                     <div className="flex justify-between items-center">
//                       <div>
//                         <h2 className="text-xl font-semibold">{pkg.package_name}</h2>
//                         <p><strong>Type:</strong> {pkg.package_type}</p>
//                         <p><strong>Total Fees:</strong> ${pkg.total_fees}</p>
//                         <p><strong>Payment Plan:</strong> {pkg.payment_plan}</p>
//                         <p><strong>Status:</strong> {pkg.is_active ? 'Active' : 'Inactive'}</p>
//                         <p><strong>Expiration Date:</strong> {pkg.expiration_date}</p>
//                       </div>
//                       <div className="flex space-x-2">
//                         <button 
//                           onClick={() => handleEdit(pkg._id)} 
//                           className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
//                         >
//                           Edit
//                         </button>
//                         <button 
//                           onClick={() => handleDelete(pkg._id)} 
//                           className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
//                         >
//                           Delete
//                         </button>
//                       </div>
//                     </div>
//                     {pkg.courses.length > 0 && (
//                       <div>
//                         <h3 className="font-medium mt-4">Courses:</h3>
//                         <ul className="list-disc pl-5 space-y-2">
//                           {pkg.courses.map(course => (
//                             <li key={course._id}>
//                               <p><strong>Title:</strong> {course.title}</p>
//                               <p><strong>Id:</strong> {course._id}</p>
//                               <p><strong>Category:</strong> {course.category}</p>
//                               <p><strong>Price:</strong> ${course.price}</p>
//                               <p><strong>Instructor ID:</strong> {course.instructor_id}</p>
//                             </li>
//                           ))}
//                         </ul>
//                       </div>
//                     )}
//                   </li>
//                 ))}
//               </ul>
//             ) : (
//               <p>No packages available</p>
//             )}
//           </div>
//         </div>
//       </div>
//     </Wrapper>
//   );
// };

// export default PackageList;







import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetPackagesQuery } from '../../store/services/productService'; // Adjust import path
import Wrapper from './Wrapper'; // Adjust import path
import ScreenHeader from '../../components/ScreenHeader'; // Adjust import path
import { Navigate, useNavigate } from 'react-router-dom';
const apiimage = process.env.REACT_APP_API_PICTURE;

const PackageList = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const { data: packages, error, isLoading } = useGetPackagesQuery();
  console.log("this packahe data ", packages)
  const navigate = useNavigate();
  const serverBaseURL = apiimage;

  
  if (isLoading) return <p>{t('loading')}</p>; // You might want to add a 'loading' translation key

  if (error) {
    return (
      <p className="text-red-600">
        {error.data?.msg || t('error-fetching-packages')}
      </p>
    );
  }

  const handleEdit = (packageId) => {
    // Implement edit functionality
    navigate(`/dashboard/update/admin/package/${packageId}`);
    console.log('Edit package:', packageId);
  };

  const handleDelete = (packageId) => {
    // Implement delete functionality
    console.log('Delete package:', packageId);
  };

  return (
    <Wrapper>
      <div className="container mx-auto p-4 bg-yellow-400 text-black">
        <ScreenHeader title={t('admin-get-package-list-title')} />
        <div className="flex flex-wrap -mx-2">
          <div className="w-full lg:w-8/12 px-2">
            {packages && packages.length > 0 ? (
              <ul className="space-y-4">
                {packages.map(pkg => (
                  <li key={pkg._id} className="p-4 bg-white shadow-md rounded-md">
                    <div className="flex justify-between items-center">
                      <div>
                        <h2 className="text-xl font-semibold">{pkg.package_name}</h2>
                        <p><strong>{t('package-list-type')}:</strong> {pkg.package_type}</p>
                        <p><strong>{t('package-list-total-fees')}:</strong> ${pkg.total_fees}</p>
                        <p><strong>{t('package-list-payment-plan')}:</strong> {pkg.payment_plan}</p>
                        <p><strong>{t('package-list-status')}:</strong> {pkg.is_active ? 'Active' : 'Inactive'}</p>
                        <p><strong>{t('package-list-expiration-date')}:</strong> {pkg.expiration_date}</p>
                        <p><strong className='bg-yellow-500'>Box Name one ? two ? three:</strong> {pkg.box_name    }</p>
                        <img    src={`${serverBaseURL}${pkg.image_url}`} alt="Product" className="w-20 h-20 rounded-md object-cover" />


                      </div>
                      <div className="flex space-x-2">
                        <button 
                          onClick={() => handleEdit(pkg._id)} 
                          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
                        >
                          {t('package-list-edit-button')}
                        </button>
                        <button 
                          onClick={() => handleDelete(pkg._id)} 
                          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
                        >
                          {t('package-list-delete-button')}
                        </button>
                      </div>
                    </div>
                    {pkg.courses.length > 0 && (
                      <div>
                        <h3 className="font-medium mt-4">{t('package-list-courses-title')}:</h3>
                        <ul className="list-disc pl-5 space-y-2">
                          {pkg.courses.map(course => (
                            <li key={course._id}>
                              <p><strong>{t('package-list-course-title')}:</strong> {course.title}</p>
                              <p><strong>{t('package-list-course-id')}:</strong> {course._id}</p>
                              <p><strong>{t('package-list-course-category')}:</strong> {course.category}</p>
                              <p><strong>{t('package-list-course-price')}:</strong> ${course.price}</p>
                              <p><strong>{t('package-list-course-instructor-id')}:</strong> {course.instructor_id}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{t('package-list-no-packages')}</p>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PackageList;
