import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setInstructorToken } from '../../store/reducers/authReducer';
import { setSuccess } from '../../store/reducers/globalReducer';
import { showError } from '../../utils/ShowError';
import { useInstructorRegistersMutation } from '../../store/services/authService';
import HomeNav from '../../components/CommonCompo/HomeNav';


const TeacherRegister = () => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        username: '',
        name: '',
        email: '',
        password: '',
        phone_number: '',
        userimage: null
    });

    const [errors, setErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState(''); // Use state for error messages
    const [registerTeacher, response] = useInstructorRegistersMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setState({ ...state, userimage: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        Object.keys(state).forEach(key => {
            formData.append(key, state[key]);
        });
        registerTeacher(formData);
    };

    useEffect(() => {
        if (response.isError) {
            setErrors(response?.error?.data?.errors || []);
        }
    }, [response?.error]);

    useEffect(() => {
        if (response.isSuccess) {
            const {  instructor } = response.data.maindata;
            const { token } = response.data;
            console.log(response)
            console.log(response.data)
            if (instructor) {
                localStorage.setItem('instructor-token', token);
                dispatch(setInstructorToken(token));
                dispatch(setSuccess(response?.data?.msg));
                console.log("response")

                navigate('/dashboard/teacher');
            } else {
                setErrorMessage('No admin privileges'); // Set error message state
                navigate('/auth/admin-login');
            }
        }
    }, [response.isSuccess, dispatch, navigate]);

    return (
        <>
        <HomeNav />

        <div className="mt-[70px] pb-[80px] flex flex-wrap justify-center">
            <div className="w-full sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12 p-6">
                <form onSubmit={handleSubmit} className="bg-white rounded-lg border border-gray-200 p-10">
                    <h1 className="heading mb-5">
                        {/* Teacher Registration */}
                        {t('instructorregister-title')}

                        </h1>

                    {errors.length > 0 && errors.map((error, key) => (
                        <div key={key} className="error">
                            {error.msg}
                        </div>
                    ))}

                    {errorMessage && <div className="error">{errorMessage}</div>} {/* Display error message */}

                    <div className="mb-4">
                        <label htmlFor="username" className="form-label">
                            {/* Username */}
                            {t('instructorregister-username')}

                            
                            </label>
                        <input type="text" name="username" id="username" className={`form-input ${showError(errors, 'username') ? 'border-rose-600 bg-rose-50' : 'border-gray-300 bg-white'}`} placeholder="Username..." value={state.username} onChange={handleInputChange} />
                        {showError(errors, 'username') && <span className="error">{showError(errors, 'username')}</span>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="name" className="form-label">
                            {/* Name */}
                            {t('instructorregister-name')}

                            
                            </label>
                        <input type="text" name="name" id="name" className={`form-input ${showError(errors, 'name') ? 'border-rose-600 bg-rose-50' : 'border-gray-300 bg-white'}`} placeholder="Name..." value={state.name} onChange={handleInputChange} />
                        {showError(errors, 'name') && <span className="error">{showError(errors, 'name')}</span>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="email" className="form-label">
                            
                            {/* Email */}
                            {t('instructorregister-email')}

                            </label>
                        <input type="email" name="email" id="email" className={`form-input ${showError(errors, 'email') ? 'border-rose-600 bg-rose-50' : 'border-gray-300 bg-white'}`} placeholder="Email..." value={state.email} onChange={handleInputChange} />
                        {showError(errors, 'email') && <span className="error">{showError(errors, 'email')}</span>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="password" className="form-label">
                            {/* Password */}
                            {t('instructorregister-password')}

                            
                            </label>
                        <input type="password" name="password" id="password" className={`form-input ${showError(errors, 'password') ? 'border-rose-600 bg-rose-50' : 'border-gray-300 bg-white'}`} placeholder="Password..." value={state.password} onChange={handleInputChange} />
                        {showError(errors, 'password') && <span className="error">{showError(errors, 'password')}</span>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="phone_number" className="form-label">
                            
                            {/* Phone Number */}
                            {t('instructorregister-phonnumber')}

                            </label>
                        <input type="text" name="phone_number" id="phone_number" className={`form-input ${showError(errors, 'phone_number') ? 'border-rose-600 bg-rose-50' : 'border-gray-300 bg-white'}`} placeholder="Phone Number..." value={state.phone_number} onChange={handleInputChange} />
                        {showError(errors, 'phone_number') && <span className="error">{showError(errors, 'phone_number')}</span>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="userimage" className="form-label">
                            
                            {/* Profile Image */}
                            {t('instructorregister-profileimage')}

                            
                            </label>
                        <input type="file" name="userimage" id="userimage" className="form-input" onChange={handleFileChange} />
                        {showError(errors, 'userimage') && <span className="error">{showError(errors, 'userimage')}</span>}
                    </div>

                    <div className="mb-4">
                        {/* <input type="submit" value={`${response.isLoading ? 'Loading...' : 'Sign Up'}`} className="btn btn-indigo w-full" disabled={response.isLoading} /> */}
                        <input type="submit" value={`${response.isLoading ? 'Loading...' : t('instructorregister-button')}`} className="btn btn-indigo w-full" disabled={response.isLoading} />
                    </div>

                    <div>
                        <p>
                            {/* Already have an account ?  */}
                            {t('instructorregister-down-line')}

                            <span className="capitalize font-medium text-base text-black"><Link to="/auth/instructor-login">
                            
                            {/* sign in */}
                            {t('instructorregister-down-line-sign-in')}

                            
                            </Link></span></p>
                    </div>
                </form>
            </div>
        </div>
        
        </>
      
    );
};

export default TeacherRegister;
