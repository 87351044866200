import React from 'react';
import { useParams, useNavigate,Link } from 'react-router-dom';
import { useGetMainCoursesQuery } from '../../store/services/CourseServiceMain';
import Spinner from '../Spinner';
import HomeNav from './HomeNav';
import { useGetPackageByIdQuery, } from '../../store/services/SupService'; // Adjust import path
import Footer from './FooterLms';
const apiimage = process.env.REACT_APP_API_PICTURE;


const CoursesList = () => {
    const { id } = useParams();

 
  const navigate = useNavigate();
//   const { data = [], isFetching, error, isLoading } = useGetMainCoursesQuery();

  const { data: packageData, error: fetchError ,isLoading,isFetching} = useGetPackageByIdQuery(id? id : "");
  // const serverBaseURL = 'https://api.wgktrade.com'; // Change this as needed
  const serverBaseURL = apiimage; // Change this as needed

  console.log("req.data ", packageData);

  if (isLoading) return (
    <>
    <HomeNav/>
    <div className="p-4 min-h-[80vh] h-auto mt-[96px] ">
    <p className='bg-green-600 text-white p-4'>Loading ...........</p>  
      
      </div>
<Footer/>
    </>
);
  if (fetchError) return (
    <>
    <HomeNav/>
    <div className="p-4 min-h-[80vh] h-auto mt-[96px] ">
    <p className='bg-red-600 text-white p-4'>Error fetching course details.</p>  
      
      </div>
<Footer/>
    </>
);

  return (
    <>
      <HomeNav />
      <div className="p-4 mt-[80px] min-h-[80vh] h-auto">
        <h2 className="text-2xl font-semibold mb-4">Courses</h2>
       



{!isFetching ? packageData?.courses?.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {packageData?.courses.map((course) => (
              <Link to={`/coursesmainsingle/${course._id}`} key={course._id} className="block">
                <div className="p-4 bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300 border border-transparent hover:border-red-500 hover:-translate-y-1 transform transition-transform duration-300">
                  <img
                      src={`${serverBaseURL}${course.thumbnail_url}`}

                    alt={course.title}
                    className="w-full h-48 object-cover rounded-t-lg"
                  />
                  <div className="p-4">
                    <h3 className="text-xl font-semibold mb-2">{course.title}</h3>
                    <p className="text-gray-600 mt-2">Category: {course.category}</p>
                    <p className="text-gray-600">Price: ${course.price}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : 'No products!' : <Spinner />}

      </div>
    </>
  );
};

export default CoursesList;
