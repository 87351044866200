


import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';


const Footer = () => {
  // const { t } = useTranslation(); // Use translation hook
  const { t, i18n } = useTranslation(); // i18n gives access to the current language


  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          {/* About Section */}
          <div className="w-full ml-1 sm:w-1/2 md:w-1/4 mb-6">
            <h3 className="text-xl font-bold mb-4">{t('footer-about-us')}</h3>
            <p className="text-yellow-500">
              {t('footer-about-description')}
            </p>
          </div>
          
          {/* Quick Links Section */}
          <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
            <h3 className="text-xl font-bold mb-4">{t('footer-quick-links')}</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/aboutus" className="text-yellow-500 hover:text-white transition duration-300">
                  {t('footer-about')}
                </Link>
              </li>
              <li>
                <Link to="/coursesmain" className="text-yellow-500 hover:text-white transition duration-300">
                  {t('footer-courses')}
                </Link>
              </li>
              <li>
                <Link to="/contactus" className="text-yellow-500 hover:text-white transition duration-300">
                  {t('footer-contact')}
                </Link>
              </li>
              <li>
                <Link to="/faqs" className="text-yellow-500 hover:text-white transition duration-300">
                  {t('footer-faq')}
                </Link>
              </li>
            </ul>
          </div>
          
          {/* Contact Us Section */}
          <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
            <h3 className="text-xl font-bold mb-4">{t('footer-contact-us')}</h3>
            <p className="text-yellow-500">{t('footer-address')}</p>
            <p className="text-yellow-500 mt-2">{t('footer-email')}</p>
            <p className="text-yellow-500" >
              

            {/* <span className="ltr-phone" style={{ direction:"ltr", unicodeBidi:'bidi-override'}}> : +212 60 133 8528</span> */}

              {/* {t('footer-phone')} */}

              {/* {t('footer-phone')} +212 60 133 8528 */}


               {/* +212 60 133 8528   */}


                 {/* Conditionally rendering the phone number based on language */}
        {i18n.language === 'ar' ? (

          <>
                            <span className="ltr-phone" style={{ direction:"ltr", unicodeBidi:'bidi-override'}}> : +212 60 19 38 528</span>

                  {t('footer-phone')} 

          
          </>
          ) : (
            <>
                              {t('footer-phone')} 

            </>
        )}


{/* <span className="ltr-phone" style={{ direction:"ltr", unicodeBidi:'bidi-override'}}>+212 60 133 8528</span> */}
               
               </p>
          </div>
          
          {/* Social Media Section */}
          <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
            <h3 className="text-xl font-bold mb-4">{t('footer-follow-us')}</h3>
            <div className="flex space-x-4">
              <a href="https://www.facebook.com/profile.php?id=100064061914556&mibextid=ZbWKwL" className="text-gray-400 hover:text-white transition duration-300">
                <i className="fab fa-facebook-f"></i> {t('footer-facebook')}
              </a>
              <a href="https://youtube.com/@profchinwimath1528?si=hH9SuBvTImme7DV_" className="text-gray-400 hover:text-white transition duration-300">
                <i className="fab fa-twitter"></i> {t('footer-twitter')}
              </a>
              <a href="https://www.instagram.com/profchinwi?igsh=d25xdG9jeW5mN3Yy" className="text-gray-400 hover:text-white transition duration-300">
                <i className="fab fa-instagram"></i> {t('footer-instagram')}
              </a>
           
            </div>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="border-t border-gray-800 pt-6 mt-6 text-center">
          <p className="text-gray-500">{t('footer-copyright')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;



