import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiUrl = process.env.REACT_APP_API_URL;
const productServiceTeacher = createApi({
  reducerPath: 'productsTeacher',
  tagTypes: 'products',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiUrl}/api/teacher/`,   
    // baseUrl: 'http://localhost:5000/api/teacher/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.instructorToken;
      console.log(token)
      headers.set('authorization', token ? `Bearer ${token}` : '');
      return headers;
    }
  }),
  endpoints: (builder) => {
    return {
      // TeacherController
      cProduct: builder.mutation({
        query: (formData) => {
          return {
            url: '/createcoursetech',
            method: 'POST',
            body: formData,
          }
        },
        // invalidatesTags: ['products']
      }),

      // TeacherController
      deleteVideo: builder.mutation({
        query: ({ id, videoId }) => ({
          url: `courses/${id}/videos/${videoId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['products'], // Assuming the 'products' tag is also tied to video data

      }),

      // TeacherController
      updateProduct: builder.mutation({
        query: data => {
          return {
            url: '/courseupdatetech',
            method: 'PUT',
            body: data
          }
        },
        invalidatesTags: ['products']
      }),


      // TeacherController
      getProducts: builder.query({
        query: ({ page, teacherId }) => {
          return {
            url: `/getcoursesallteacher/${page}?teacherId=${teacherId}`,
            method: 'GET'
          }
        },
        providesTags: ['products']
      }),

      // TeacherController
      getProductsVideo: builder.query({
        query: ({ page, teacherId }) => {
          return {
            url: `/getcoursesallteacher/video/${page}?teacherId=${teacherId}`,
            method: 'GET'
          }
        },
        providesTags: ['products']
      }),

      // TeacherController
      getStudents: builder.query({
        query: ({ page, teacherId }) => {
          return {
            url: `/allstundetpaidteach/${page}?teacherId=${teacherId}`,
            method: 'GET'
          }
        },
        providesTags: ['products']
      }),

      // TeacherController
      getProduct: builder.query({
        query: id => {
          return {
            url: `/couresesingletech/${id}`,
            method: 'GET'
          }
        },
        providesTags: ['products']
      }),

      // TeacherController all catagory 
      allCategories: builder.query({
        query: () => {
          return {
            url: "allcategoriestech",
            method: "GET",
          };
        },
      }),

      // TeacherController  
      deleteProduct: builder.mutation({
        query: id => {
          return {
            url: `/deletecoursetech/${id}`,
            method: 'DELETE'
          }
        },
        invalidatesTags: ['products']
      }),


      // cProduct: builder.mutation({
      //     query: (formData) => {
      //        return {
      //            url: '/create-product',
      //            method: 'POST',
      //            body: formData,
      //        }
      //     },
      //     // invalidatesTags: ['products']
      // }),


      // cProduct: builder.mutation({
      //     query: ({ form }) => ({
      //       url: '/create-product',
      //       method: 'POST',
      //       body: form,
      //       headers: {
      //         'Authorization': `Bearer ${localStorage.getItem('token')}`,
      //       },
      //     }),
      //   }),








      uploadVideo: builder.mutation({
        query: (formData) => ({
          url: '/upload-video',
          method: 'POST',
          body: formData,
        }),
      }),

      //         // TeacherController
      //         getProduct: builder.query({
      //             query: id => {
      //             return {
      //                 url: `/couresesingletech/${id}`,
      //                 method: 'GET'
      //             }
      //             },
      //             providesTags: ['products']
      //         }),

      //         // TeacherController all catagory 
      //   allCategories: builder.query({
      //     query: () => {
      //       return {
      //         url: "allcategoriestech",
      //         method: "GET",
      //       };
      //     },
      //   }),

      getCourse: builder.query({
        query: (id) => `coursesvideoalltech/${id}`,
      }),


    }
  }
})
export const { useCProductMutation, useAllCategoriesQuery, useGetCourseQuery, useDeleteVideoMutation, useUploadVideoMutation, useDeleteProductMutation, useUpdateProductMutation, useGetProductsQuery,useGetProductsVideoQuery ,useGetStudentsQuery, useGetProductQuery } = productServiceTeacher;
export default productServiceTeacher