const VideosPreview = ({url, heading}) => {
    return (
        <div>
            {url && (
                <div>
                    <h1 className="right-heading">{heading}</h1>
                    <div className="preview-video">
                        <video src={url} controls className="w-full h-full object-cover"></video>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideosPreview;
